<template>
  <!-- :class="{ 'shop_box rightBoxBg': true, shop_box_model: device !== 'PC' }" -->
  <div id="live_drops" class="shop_box rightBoxBg lg:h-screen"
  :class="[$breakpoint().le('md')?'shop_box_model':'']">

    <template v-if="bingoCount && bingoCount?.bingo">
      <div key="bingo-title" class="shop_title hidden lg:inline-block w-full">BINGO</div>
      <transition>
        <div  key="bingo-item"
              class="shop_card hover-scale"
              @click="toPage('/everything#section-8')"
              style="padding: 0;"
        >

          <div
              class="card_content"
          >
            <div class="card_img" :class="[device !== 'PC'?'card_img_model card_img_model_bingo':'']">
              <div class="img_content flex rcenter img_content_active">
                <v-img
                    :lazy-src="$S3Utils.resizeGoodsPictureLazy(bingoCount?.bingo?.picture)"
                    :src="$S3Utils.resizeGoodsPicture(bingoCount?.bingo?.picture,0.3)"

                    class="shop_img"
                >
                </v-img>
              </div>
            </div>
            <div class="card_func flex rcenter between ">
              <div class="func_play flex allcenter">
              </div>
              <div class="func_money_bingo func_money small-tag-span" v-if="bingoCount?.count">
                x {{ bingoCount?.count }}
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>
<transition-group>
    <div key="livedrop-title" class="shop_title hidden lg:inline-block w-full">LIVE DROPS</div>
    <div
      :class="{ shop_card: true,}"
      :style="{
              backgroundColor: bgcolor[dayStatus][item.goodsLevel],
            }"
      v-for="(item, index) in liveDropsLeaderboard"
      :key="`leaderboard_${item.hashId}_${index}`"
      @mouseenter="openAniLeaderboard = index + 1"
      @mouseleave="openAniLeaderboard = ''"
    >
      <transition>
        <div
          :class="{
            card_content: true,
          }"
          v-if="item.boxId"
          style=""
          @click.stop="toSplitBox(item)"
        >
          <div class="card_img" :class="{ card_img_model: device !== 'PC' }">
            <div
              :class="{
                'img_content flex rcenter': true,
                img_content_active: index + 1 == openAniLeaderboard,
                img_content_noactive: index + 1 !== openAniLeaderboard,
              }"
            >
              <!-- 盒子图片 -->
              <v-img :alt="item?.boxCode??item.boxPicture"
                  :lazy-src="$S3Utils.resizeBoxPictureLazy(item.boxPicture)"
                  :src="$S3Utils.resizeBoxPictureLazy(item.boxPicture)"
                class="shop_img"
                contain

              >
              </v-img>
              <!-- 商品图片 -->
              <v-img :alt="item?.boxCode??item.goodsPicture"
                  :lazy-src="$S3Utils.resizeGoodsPictureLazy(item.goodsPicture)"
                  :src="$S3Utils.resizeGoodsPicture(item.goodsPicture,0.3)"
                class="shop_img"
                contain
              >
              </v-img>
            </div>
          </div>
          <div class="card_func flex rcenter between">
            <div class="func_play flex allcenter">

              <!-- <v-img
                src="/assets/img/rightshop/play.png"
                class="play_img"
                @click="toSplitBox(item.boxId)"
              ></v-img> -->
            </div>
            <div class="func_money small-tag-span">
              ${{ ((index + 1 == openAniLeaderboard?item.boxPrice:item.price) / 100) | matterPriceDecimal }}
            </div>
          </div>
        </div>
      </transition>
      <div class="leaderboard-rand z-2">
        <!-- <img class="" :src="$S3Utils.getLevel(index+1)" :alt="item.userLevel"> -->
        <!-- mdiStarOutline -->
        <v-icon color="#FFF" size="16px">{{icons.mdiStarOutline}}</v-icon>

      </div>
      <div :class="{ userinfo: true, userinfo_active: index + 1 == openAniLeaderboard }">
        <div class="userinfo_box">
          <v-img  :alt="item?.boxCode??item.avatar"
            :src="$S3Utils.resizeAvatar(item.avatar)"
            class="userinfo_img"
            width="100%"
            height="100%"
          ></v-img>
          <!-- <span class="userinfo_leave flex allcenter"
            ><span>{{ item.userLevel }}</span></span
          > -->
          <!-- <icon className="userinfo_leave" :name="`level-${item.userLevel || 0}`"></icon> -->
          <!-- <img class="userinfo_leave" :src="$S3Utils.getLevel(item?.userLevel)" :alt="item.userLevel"> -->
        </div>
      </div>
    </div>
    <!-- 正常livedrop -->
    <div class="xs:overflow-hidden"
      :class="{ shop_card: true,}"
      :style="{
              backgroundColor: bgcolor[dayStatus][item.goodsLevel],
            }"
      v-for="(item, index) in liveDropsList"
      :key="`livedrop_${item.hashId}_${index}`"
      @mouseenter="openAni = index + 1"
      @mouseleave="openAni = ''"
    >
      <transition>
        <div
          :class="{
            card_content: true,
          }"
          v-if="item.boxId"
          style=""
          @click.stop="toSplitBox(item)"
        >
          <div class="card_img" :class="{ card_img_model: device !== 'PC' }">
            <div
              :class="{
                'img_content flex rcenter': true,
                img_content_active: index + 1 == openAni,
                img_content_noactive: index + 1 !== openAni,
              }"
            >
              <!-- 盒子图片 -->
              <v-img :alt="item?.boxCode??item.boxPicture"
                  :lazy-src="$S3Utils.resizeBoxPictureLazy(item.boxPicture)"
                  :src="$S3Utils.resizeBoxPictureLazy(item.boxPicture)"
                class="shop_img"
                contain

              >
              </v-img>
              <!-- 商品图片 -->
              <v-img :alt="item?.boxCode??item.goodsPicture"
                  :lazy-src="$S3Utils.resizeGoodsPictureLazy(item.goodsPicture)"
                  :src="$S3Utils.resizeGoodsPicture(item.goodsPicture,0.3)"
                class="shop_img"
                contain
              >
              </v-img>
            </div>
          </div>
          <div class="card_func flex rcenter between">
            <div class="func_play flex allcenter">

              <!-- <v-img
                src="/assets/img/rightshop/play.png"
                class="play_img"
                @click="toSplitBox(item.boxId)"
              ></v-img> -->
            </div>
            <div class="func_money small-tag-span">
              ${{ ((index + 1 == openAni?item.boxPrice:item.price) / 100) | matterPriceDecimal }}
            </div>
          </div>
        </div>
      </transition>
      <div :class="{ userinfo: true, userinfo_active: index + 1 == openAni }">
        <div class="userinfo_box">
          <v-img  :alt="item?.boxCode??item.avatar"
            :src="$S3Utils.resizeAvatar(item.avatar)"
            class="userinfo_img"
            width="100%"
            height="100%"
          ></v-img>
          <!-- <span class="userinfo_leave flex allcenter"
            ><span>{{ item.userLevel }}</span></span
          > -->
          <!-- <icon className="userinfo_leave" :name="`level-${item.userLevel || 0}`"></icon> -->
          <!-- <img class="userinfo_leave" :src="$S3Utils.getLevel(item?.userLevel)" :alt="item.userLevel"> -->
        </div>
      </div>
    </div>
  </transition-group>
  </div>
</template>

<script>
import bgcolor from "@/utils/goodsLevelColor2.js";
import {mapGetters} from "vuex";
import { getGlobalBingoCount } from "@/api/product_box";
import { mdiStarOutline } from "@mdi/js";
let Queue = (function () {
  const items = new WeakMap();
  class Queue {
    constructor() {
      items.set(this, []);
    }
    enqueue(element) {
      let q = items.get(this);
      q.push(element);
    }
    dequeue() {
      let q = items.get(this);
      let r = q.shift();
      return r;
    }
    front() {
      let q = items.get(this);
      return q[0];
    }
    isEmpty() {
      let q = items.get(this);
      return q.length === 0;
    }
    size() {
      let q = items.get(this);
      return q.length;
    }
    print() {
      let q = items.get(this);
      console.log(q.toString());
    }
  }
  return Queue;
})()

export default {
  name: "rightShopBox",
  data() {
    return {
      icons: {
        mdiStarOutline,
      },
      openAni: "",
      openAniLeaderboard: "",
      boxHeight: document.documentElement.clientHeight,
      bgcolor,
      liveDropsList:[],
      itemQueue: null,
      testTimer:null,
      testTimerCount:0,
      getGlobalBingoCountTimer: null,
    };
  },
  watch: {
    liveDrops: {
      handler(list){
        if(list.length===0){
          return
        }
        if (!this.itemQueue) {
          this.itemQueue = new Queue()
        }
        if (this.liveDropsList.length === 0) {
          this.liveDropsList = list
        } else {
          list.filter(item => this.liveDropsList.findIndex(v => v.hashId === item.hashId) === -1)
              .forEach(item => {
                if (item) {
                  this.itemQueue.enqueue(item)
                }
              })
        }

        this.pushItem()
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters({
      dayStatus: "user/dark",
      device: "user/device",
      liveDrops: "appData/liveDrops",
      liveDropsLeaderboard: "appData/liveDropsLeaderboard",
      bingoCount: "appData/bingoCount",
    })
  },
  created() {
    setTimeout(this.getGlobalBingoCount,3000)
    this.startGetGlobalBingoCount()
  },
  beforeDestroy(){
    clearInterval(this.getGlobalBingoCountTimer)
  },
  methods: {
    pushItem(){
      let item = this.itemQueue.dequeue()
      if(item && this.liveDropsList.findIndex(v => v.hashId === item.hashId)===-1){
        this.liveDropsList.unshift(item)
      }
      if(this.itemQueue.size()>0){
        setTimeout(()=>this.pushItem(),300)
      }
    },
    toPage(url){
      this.$router.push(url);
    },
    //跳转
    toSplitBox(item) {
      if(item?.boxCode) {
        this.$router.push(`/box/${item.boxCode}`);
      } else {
        this.$router.push(`/boxinfo/${item.boxId}`);
      }
    },
    toPlayBattle(item) {
      if (!item.battleId) {
        return;
      }
      this.$router.push({
        name: "detail",
        query: { id: item.battleId },
      });
    },
    startGetGlobalBingoCount(){
      this.getGlobalBingoCountTimer = setInterval(this.getGlobalBingoCount,60*1000)
    },
    getGlobalBingoCount(){
      getGlobalBingoCount().then(res=>{
          this.$store.dispatch("appData/setBingoCount", res.data??{})
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.5s;
}
.v-enter {
  transform: translateX(-100%);
}
.v-enter-to {
  transform: translateX(0);
}
.v-leave-to {
  transform: translateX(-100%);
}
@keyframes startMove {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes move {
  0% {
  }
  60% {
    transform: translateX(0);
  }
}
@keyframes unmove {
  0% {
  }
  60% {
    transform: translateX(-100%);
  }
}
@keyframes roteandOp {
  0% {
  }
  100% {
    transform: translateX(100%);
  }
}
.shop_box {
  width: 100%;
  font-size: 9.36px;
  padding: $header-menu-height 8px 0;
  box-sizing: border-box;
  // background-color: #fff !important;
  overflow-y: scroll;
  .shop_title {
    font-weight: 700;
    text-align: left;
    margin: 11.7px 0 10.14px;
  }
  .shop_card {
    background-size: contain;
    background-position: center center;
    border-radius: 10px;
    width: 100%;
    padding: 0.3125rem;
    z-index: 2;
    margin: 8px 0;
    box-sizing: border-box;
    background-color: var(--v-grey_black-base);
    //margin-bottom: 6.24px;
    position: relative;
    overflow: hidden;
    .userinfo {
      position: absolute;
      top: 0;
      right: 0;
      //z-index: 100;
      width: 24px;
      height: 24px;
      transition: all 0.3s;
      transform: rotate(-45deg);
      opacity: 0;
      .userinfo_box {
        width: 100%;
        height: 100%;
        position: relative;
        .userinfo_img {

          border-radius: 0 10px 0 0;
        }
        .userinfo_leave {
          position: absolute;
          bottom: -40%;
          left: -40%;
          width: 0.8rem;
          height: 0.8rem;
          //padding: 1px;
          background-color: var(--v-success-base);
          text-align: center;
          border-radius: 50%;
          border: 1px solid #fff;
        }
      }
    }
    //角色头像移入动画
    .userinfo_active {
      transform: rotate(0);
      opacity: 1;
    }
    //.card_check {
    //  width: 100%;
    //  justify-content: flex-end;
    //  .top_check {
    //    width: 9px;
    //    height: 9px;
    //  }
    //}
    .card_content {
      cursor: pointer;
      transform: translateX(0);
      transition: all 0.5s;
      animation: startMove 1s cubic-bezier(0.075, 0.82, 0.165, 1);

      .card_img {

        //margin: 0.375rem 0 0.6875rem;
        width: 100%;
        //height: 6rem;
        aspect-ratio: 1;
        overflow: visible;
        position: relative;
        .img_content {
          position: absolute;
          top: 0;
          left: 0;
          transition: all 0.5s cubic-bezier(0, 1, 1, 1);
          transform: translateX(-100%);
          width: 100%;
          height: 100%;

          .shop_img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
        //图片内容移入动画
        .img_content_active {
          transform: translateX(0);
        }
      }
      .card_img_model {
        height: 4rem;
        //margin-bottom: 8px;
      }
      .card_img_model_bingo {
        height: 4.5rem;
        //margin-bottom: 8px;
      }
      .card_func {
        width: 100%;
        .func_play {
          cursor: pointer;
          .icon-player {
            width: 17px;
            height: 17px;
            color: #ccc;
            transition: all 0.5s;
          }
          .icon-player:hover {
            color: #999999;
          }
        }
        .func_money_bingo {
          right: 0.3125rem!important;
          bottom: 0.3125rem!important;
        }
        .func_money {
          padding: 6px;
          position: absolute;
          right: 0;
          bottom: 0;

          @media (min-width: 100px) and (max-width: 600px) {
            padding: 3px 6px;
          }
          font-size: 12px;
          font-family: Poppins;
          font-weight: 700;
          line-height: 14px;
          //background-color: var(--v-trans_grey-base);
        }
      }
    }
    .card_content_start {
      animation: startMove 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}
.shop_box::-webkit-scrollbar {
  height: 0 !important;
  width: 0rem !important;
}

//移动样式
.shop_box_model {
  text-align: left;
  top: $header-menu-height;
  padding: 0.5rem 0 0;
  white-space: nowrap;
  overflow-y: none;
  overflow-x: scroll;
  background-color: #fff !important;
}

.leaderboard-rand {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8A8A8A;
  border-radius: 10px 0 0 0;
}
@media (max-width: 1023px) {

.shop_box {
  .shop_card {
    display: inline-block;
    width: 100px !important;
    //height: 100px !important;
    margin: 0 0 0 0.25rem!important;
    padding: 0.25rem;
  }
}
}

</style>

<template>
  <div>

    <v-dialog content-class="v-dialog-right" v-model="value"  @click:outside="close" persistent scrollable  min-height="90%">
      <v-card :elevation="0" rounded="0" color="dialog">
        <v-card-title class="font-weight-bold lighten-2 d-flex justify-space-between align-center ">
          <div>PLAZA</div>
          <icon class-name="pointer v-dialog-close" name="dialog-close" width="25px" height="25px"
            @click="close"></icon>
        </v-card-title>
        <v-card-text class="font-weight-bold black--text pa-0  white scrollBarHidden" id="messageBoxWrapper">
          <div @focus="focus" class="scrollBarHidden">
            <transition>
              <div :class="{
                chatbox: true,
                chatbox_scale: isScale && device == 'PC',
                chatbox_model: device !== 'PC',
              }">
                <div style="height: 100%" class="cflex">

                  <div class="chatbox_content cflex scrollBarHidden">
                    <!--- <chat-user v-for="(item, index) in 15" :key="index"></chat-user>
        -->
                    <div class="im-chat-main xs:px-2 scrollBarHidden">
                      <div class="im-chat-main-left">
                        <div class="im-chat-main-box messages scrollBarHidden" id="message-box" v-scroll.self="onscroll"
                          ref="messageBox">
                          <ul style="padding: 0" ref="chatBoxUl" id="message-box-ul">
                            <li class="xs:my-2 xs:py-2 xs:pa-4 flex "  v-for="item in messages" :key="item.id" :class="{ 'im-chat-mine': item.mine }">

                              <div class="im-chat-user pointer" v-if="item.userRespDTO">
                                <div class="head-icon">
                                  <v-img alt="avatar" v-if="item && item.userRespDTO && item.userRespDTO.avatar"
                                    :src="$S3Utils.resizeAvatar(item.userRespDTO.avatar)" width="100%" height="100%"
                                    contain @click="jumpToDetail(item)" style="border-radius: 50%"></v-img>
                                  <!-- <img class="head-inside" :src="$S3Utils.getLevel(item.userRespDTO.level)"
                                    :alt="item.userRespDTO.level"> -->
                                </div>
                              </div>
                              <div class="xs:pl-2 w-full">
                                <div class="xs:text-3 primary--text">
                                  <span>{{ item.userRespDTO.nickname }}</span>
                                </div>
                                <div class=" text-3 font-700">
                                  <div v-if="item.type === 1">{{ item.content }}</div>
                                  <!--消息类型 1 用户消息  2 公告-拆盲盒得高价值物品得公告 3 公告-对战得高价值物品得公告-->
                                  <template v-if="(item.type === 2 || item.type === 3)">
                                    <div v-if="item.content.id" @click="gotoDetail(item)">
                                      <simple-goods-card class="w-[90%]" :shop-info="item.content" />
                                    </div>
                                    <div v-else>
                                      <!--                            暂不支持-->
                                      Unsupported messages
                                    </div>
                                  </template>
                                  <!--                        <v-btn  @click="gotoBoxInfo(item)">VIEW DUEL</v-btn>-->
                                  <!--                        <v-btn v-if="item.type === 3" @click="gotoClash(item)">CLASH FOR {{ item.userRespDTO.nickname }}-->
                                  <!--                        </v-btn>-->
                                </div>
                              </div>
                            </li>
                            <div class="mt-8" style="height: 1px;"></div>
                          </ul>
                        </div>

                      </div>
                    </div>
                    <!---
                    <div class="content_des">
                      <span style="color: #ecae04">Helper </span>
                      <span>Lillpaulie</span>
                      <span style="vertical-align: super; font-size: 0.1538rem"
                        >TM</span
                      >
                      <span>- {{ contentDes }}</span>
                    </div>-->
                  </div>
                  <!-- <div class="chatbox_bottom" @mousewheel.stop="stopScroll">


                  </div> -->
                </div>
              </div>
            </transition>

          </div>


        </v-card-text>

        <v-card-actions class="xs:important-px-6 xs:important-py-4">

          <div class="w-full flex text-6  justify-between bg-white rounded-lg xs:px-2">

            <div class=" pointer flex items-center justify-center transition-transform hover:scale-120"
            @click="mineSendContent(item)" v-for="(item,i) in emojiList" :key="i">
              <div>{{item}}</div>
            </div>

          </div>

          <!-- <v-spacer></v-spacer> -->
          <!-- <v-text-field v-if="balance >= 5" v-model="messageContent" background-color="success lighten-2" solo
            :hide-details="true" @focus="focus" @keyup.enter="mineSend()">
            <v-icon @click="mineSend()" slot="append" color="#FFF">
              {{ icons.mdiSend }}
            </v-icon>
          </v-text-field> -->
          <!-- <div v-else @click="showRecharge" class="success rounded pa-2 pointer">
            You must <span class="text-decoration-underline">deposit</span> at least $5
            <br>
            to chat
          </div> -->

        </v-card-actions>

      </v-card>

    </v-dialog>

  </div>
</template>

<script>
import simpleGoodsCard from "@/components/ChatSimpleGoodsCard.vue";
import { sendText, getHistoryList0 } from "@/api/messageApi";
import { mdiSend } from "@mdi/js"
import { debounce } from "lodash-es";
import { WS_EVENT_KEY } from "@/plugins/ws/ws.event"
export default {
  name: "GroupChat",
  props: {
    value: {
			type: Boolean,
			default: false
		},
    device: {
      default: "PC",
    },
  },
  head: {
    link() {
      return [
        { rel: 'canonical', href:"https://www.onlibox.com", id: 'canonical' },
      ]
    },
  },
  components: {
    //  chatUser,
    simpleGoodsCard,
    // rechargeBox,
  },
  emits: ["input",],

  computed: {
    balance () {
      return this.$store.state.user.accountInfo?.balance / 100 || 0
    },
    accountInfo () {
      return this.$store.state.user.accountInfo
    },
  },
  created () {
    this.initGetLatestGroupMessageBus()
    this.getList()
    this.$store.dispatch("chat/readGroupMessage")
  },
  data () {
    return {
      openGoodsInfoShow: true,
      openGoodsInfo: {},
      showDialog: true,
      icons: { mdiSend },
      isScale: false,
      // showRecharge: false,
      //发送内容
      messageContent: "",
      // 上次发送消息的时间
      sendtime: 0,
      // 发送间隔时间（默认1秒）
      interval: 1000,
      // balance: 0,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        firstId: ''
      },
      messages: [],
      loading: false,
      emojiList:[
            '✌️','🍑','💩','🍆','💦',
            '🌶️',
            '🔥',
            '🍒',
            '😈',
            '👅',
            '👌',
      ],
    };
  },

  watch: {
    value (val, oval) {
      console.log(val, oval)
      // 打开后关闭
      if (val == true && oval == false) {
        this.getList();
      } else {
        // list split
        if(this.messages.length > this.page.pageSize) {
          this.messages = [...this.messages.slice(this.messages.length-this.page.pageSize)]
        }
        console.log(this.page.pageSize)
        console.log(this.messages)
      }
    }
  },
  beforeDestroy () {
    this.destroyGetLatestGroupMessageBus()
  },
  methods: {
    clickOpenGoodsInfo(item) {
      console.log(item)
      // this.openGoodsInfo = {
      //   goodsId: item.goodsId,
      // };
      // this.openGoodsInfoShow = true;
    },
    focus () {
      console.log("focus")
      this.$store.dispatch("chat/readGroupMessage")
    },
    showRecharge () {
      this.$bus.$emit("showRecharge", true);
    },
    destroyGetLatestGroupMessageBus () {
      this.$ws0.$off(WS_EVENT_KEY.WS_EVENT_GROUP_NOTICE)
    },
    initGetLatestGroupMessageBus () {
      // 注册消息
      this.$ws0.$on(WS_EVENT_KEY.WS_EVENT_GROUP_NOTICE, ({ msgId, num }) => {
        if (msgId) {
          console.log({ msgId })
          if (this.messages.length > 0) {
            // 获取新消息
            let latestMsgId = this.messages[this.messages.length - 1]?.id
            this.getList({ latestMsgId })
          } else {
            this.getList({ latestMsgId: msgId })
          }
        }
      })
    },
    scrollToBottom: function () {

      this.$nextTick(() => {
        let messageBoxWrapper = document.getElementById("messageBoxWrapper");
        let el1 = document.getElementById("message-box-ul");
        let messageBox = document.getElementById("message-box");
        console.log(el1)
        if (el1) {
          el1.style.height = `${messageBoxWrapper.clientHeight}px`

          messageBox.scrollTop = messageBox && messageBox.scrollHeight;
          //div.scrollIntoView()

        }
      });
    },
    close () {
      this.$emit("input", false);
    },

    getList (params) {
      let firstMsgId = params?.firstMsgId
      let latestMsgId = params?.latestMsgId
      if (this.loading) {
        return
      }
      this.loading = true

      getHistoryList0({
        firstMsgId,
        latestMsgId,
        pageSize: 20,
      }).then(res => {
        let list = res.data || []
        // 对比id，没有push
        if (list.length > 0) {
          let reverseList = list.reverse().map(l => this.handlerMessageItem(l))
          if (this.messages && this.messages.length > 0) {
            reverseList.forEach(item => {
              let repeatIndex = this.messages.findIndex(l => l.id === item.id)
              // 去掉重复消息
              if (repeatIndex === -1) {
                // 历史消息
                if (firstMsgId) {
                  // unshift
                  this.messages.unshift(item)
                }
                // 新的消息
                else {
                  this.messages.push(item)
                }
              }
            })
          } else {
            this.messages = reverseList
          }
        }

        // 不是历史消息到最底下
        if (!firstMsgId) {
          this.scrollToBottom()
        }
        //读取消息
        // this.readMessage();
      }).finally(() => {
        this.loading = false;
      })
    },
    handlerMessageItem (item) {
      if (item.type !== 1) {
        item.content = JSON.parse(item.content)
      }

      item.mine = item.fromId == this.accountInfo.userId
      return item
    },

    stopScroll (e) {
      e.preventDefault();
      e.stopPropagation();
    },
    onscroll (e) {
      let offsetTop = e.target.scrollTop

      if (this.loading) return;

      if (offsetTop <= 200) {
        if (this.messages && this.messages.length > 0) {
          let firstMsgId = this.messages[0].id
          this.getList({ firstMsgId })
        }
      }
    },
    nextPage() {
      if (this.messages && this.messages.length > 0) {
          let firstMsgId = this.messages[0].id
          this.getList({ firstMsgId })
        }
    },
    resetScale () {
      this.isScale = !this.isScale;
      const el = document.getElementById("message-box");
      let isBottom =
        Math.ceil(el.scrollTop) + el.clientHeight >= el.scrollHeight;


      this.$nextTick(() => {

        el.scrollTop = Math.ceil(el.scrollTop) + el.clientHeight + 0;


      });
    },
    mineSendContent: debounce(function (text) {
      this.messageContent = text
      this.mineSend();
    }, 200, {
      leading: true,
      trailing: false
    }),
    mineSend () {
      if (this.$store.state.user.userId) {
        if (this.messageContent && this.messageContent.trim() !== "") {
          sendText({ text: this.messageContent.trim() }).then(res => {
            this.$store.dispatch("chat/sendGroupMessage");
            let msgItem = res.data
            let message = this.handlerMessageItem(msgItem)
            this.messages.push(message)
            this.scrollToBottom()
            // 发送成功，push message
            // if(this.messages.length>0){
            //   // 获取新消息
            //   let latestMsgId = this.messages[this.messages.length-1]?.id
            //   this.getList({latestMsgId})
            //
            // }
          })
        }
        this.messageContent = ""
      }
    },
    gotoDetail (item) {
      let router = {}
      // 消息类型 1 用户消息  2 公告-拆盲盒得高价值物品得公告 3 公告-对战得高价值物品得公告
      if (item.type === 2) {
        router = {
          name: "boxinfo",
          params: { id: item.content.id },
        }
      } else if (item.type === 3) {
        router = {
          path: '/clash/detail',
          query: { id: item.content.id },
        }
      }
      this.$router.push(router)
    },
    readMessage () {
      let receipt = {
        userId: this.$store.state.user.userId,
        type: "0",
      };
      this.$ws.sendRead(receipt);
    },
    jumpToDetail (msg) {
      this.$router.push({
        path: "/userinfo",
        query: {
          nowShow: "friendInfo",
          friendId: msg.fromId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep input {
  color: #FFF!important;
}
.dialog-right {
  position: absolute;
  right: 0;
}
// .v-enter-active,
// .v-leave-active {
//   transition: all 0.3s ease;
// }
// .v-enter {
//   transform: translateX(100%);
// }
// .v-enter-to {
//   transform: translateX(0);
// }
// .v-leave-to {
//   transform: translateX(100%);
// }
.head-icon {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  width: 36px;
  height: 36px;
  //border: 3px solid var(--v-success-base);
  border-radius: 36px;

  background-size: 100% 100%;

  .head-inside {
    position: absolute;
    top: 20px;
    left: -2px;
    width: 20px;
    height: 20px;
    padding: 1px;
    text-align: center;
    border-radius: 18px;
    background-color: var(--v-success-base);
    border: 1px solid #fff;
  }
}

.chatbox {


  transition: all 0.5s;
 // width: 420px;
  //min-width: 320PX;
  //height: 31.25rem /* 500/16 */;
  //box-shadow: 0px 3.9px 7.8px 0px rgba(0, 0, 0, 0.2);

  //top: $header-menu-height;
  //right: 0px;
  //z-index: 1500;
  //box-sizing: border-box;
  //padding: 16px 0 10px;
  font-size: 12px;
  //overflow: hidden;
  //background-color: var(--v-white_night-base);
  //border-radius: 0 0 10px 10px;

  .chatbox_title {
    width: 100%;
    font-size: 11.7px;
    padding: 0 21.06px;
    height: 41.34px;
    box-sizing: border-box;


    .title_text {
      font-family: Poppins;
      font-weight: 700;
    }

    .title_func {
      .title_img {
        width: 20px;
        height: 20px;
        margin-left: 16px;
      }
    }
  }

  .chatbox_content {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    overflow-y: scroll;

    .content_des {
      font-size: 11.7px;
    }
  }

  //.chatbox_content::-webkit-scrollbar {
    //height: 0 !important;
    //width: 0rem !important;
  //}

  .chatbox_bottom {
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    padding: 0.5rem;
    //border-top: 0.78px solid #ececec;
    //color: #999999;
    //background-color: var(--v-boxCardBg-base);
  }
}

.chatbox_model {
  width: 100%;
  height: calc(100% - $header-menu-height);
}

//移动端
.chatbox_scale {
  width: 352px;
  height: calc(100% - $header-menu-height);
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background-color: transparent;
}

.im-chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.im-chat-top {
  border-bottom: 1px solid #cccccc;
  color: gray;
  padding: 0 0 0.2rem 1rem;
  font-size: 1.6rem;
  font-weight: bold;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .menu {
    color: gray;
    display: inline-block;
    padding: 0 10px;
  }
}

.user-model {
  .user-model-img {
    padding: 15px;
    text-align: center;

    img {
      border-radius: 50%;
    }
  }

  .user-model-item {
    display: flex;
    padding: 5px 0;

    label {
      flex: 2;
      font-weight: bold;
      text-align: right;
    }

    span {
      flex: 3;
    }
  }
}

.im-chat-main {


  .im-chat-main-left {


    .im-chat-main-box {

    }
  }

  .message-img {
    max-width: 20rem;
  }

  .im-chat-users {
    width: 180px;
    border-left: 1px solid #cccccc;

    .chat-user-list {
      list-style: none;
      margin: 0;

      .user {
        cursor: pointer;
        padding: 5px 2px;
        position: relative;
        display: flex;
        align-items: center;

        &:hover {
          background-color: #eeeeee;

          &:after {
            content: "...";
            position: absolute;
            right: 10px;
            font-weight: bold;
          }
        }

        & > .im-chat-avatar {
          width: 2.2rem;
          height: 2.2rem;
          display: inline-block;
          vertical-align: middle;

          & > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .messages {
    width: 100%;
    height: calc(100% - 3rem);
    overflow-y: scroll;

    ul {
      width: 100%;
      li {
        //background: var(--v-rightBg-base);
        background: var(--v-dialog-base);
        border-radius: 0.5rem;
      }
    }

    .im-chat-user {
      .right_back {
        right: 0;
        text-align: right;
        left: auto;

        i {
          padding-right: 15px;
        }
      }

    }

    .im-chat-user-nickname {

    }
    .message-info {
      white-space: nowrap;
      text-align: left;
    }

  }
}

.im-chat-footer {
  border-top: 1px solid gray;
  height: 15rem;
  display: flex;
  flex-direction: column;

  .im-chat-tool {
    padding: 0.5rem 1rem;
    height: 3.4rem;
    position: relative;

    i {
      font-size: 2rem;
      cursor: pointer;
      margin: 1rem;
    }

    .faces-box {
      position: absolute;
      bottom: 3.8rem;
    }

    .ivu-upload {
      display: inline-block;
    }

    .history-message-btn {
      float: right;
    }
  }

  .im-chat-send {
    height: 4rem;
    text-align: right;
    padding: 0 1rem 1rem 0;
  }
}

.ivu-scroll-wrapper {
  margin: 0 !important;
}

.ivu-scroll-container {
  padding: 15px 15px 5px;
  overflow-y: visible !important;
}

/* 重新覆盖iview 里面的 model 小于768px 时候 宽度变100% 的问题 */
@media (max-width: 768px) {
  .user-model {
    .ivu-modal {
      width: 30rem !important;
      margin: 0 auto;
    }
  }
}

.history-message {
  width: 80%;
  height: calc(100% - 30px);
}

.page {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0.5rem;
}

.ivu-drawer-body {
  padding: 0 !important;

  .messages {
    height: calc(100% - 3rem);
  }
}

.model-footer {
  text-align: right;
  margin: 10px;
}

.textarea {
  border-bottom: 0.78px solid rgb(236, 236, 236);
  width: 100%;
  resize: none;
  background-color: white !important;

  &:focus {
    border: none;
    outline: none;
  }
}

//button.btn_black {
//color: white;
//background: black;
//}
//button.white {
//color: black;
//background: white;
//}
</style>

<template>
    <div>
        <div class="sticky-navbar pointer d-flex align-center justify-center">
            <!-- Your navbar content goes here -->
            <div class="d-flex align-center">
                <!-- <v-img :src="proLogoUrl" contain alt="logo" class="sticky-navbar-logo" >
                </v-img> -->
                <div class="sticky-navbar-text">
                  Ready To Test Your Luck
                  <div v-if="$store.state.user.device!='PC'"></div>
                  OPEN 5 FREE BOX NOW
                </div>
                <div>
                    <v-btn href="https://www.onlibox.com/r/onlibox?utm_source=onlibox-lite.com" color="black" height="1.4rem" x-small depressed class="sticky-navbar-target-btn">GO</v-btn>
                </div>
            </div>
            <!-- <v-btn color="black" height="1.4rem" x-small depressed class="close-icon sticky-navbar-target-btn" @click.stop="toPro">GO</v-btn> -->

            <v-icon size="20" class="close-icon" @click.stop="readNotice">{{ icons.mdiClose }}</v-icon>
        </div>

    </div>
</template>

<script>

import { mdiClose } from "@mdi/js"

export default {
    data () {
        return {
            proLogoUrl: 'https://d122trkl556ygk.cloudfront.net/eyJidWNrZXQiOiJvbmxpYm94czMiLCJrZXkiOiJwcm8vYmFzZV9kYXRhL2hvbWVfbG9nb18yMDIzMDgyMDExNDIxMC5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiZmlsbCIsIndpZHRoIjoxMDYwLCJoZWlnaHQiOjI0OH0sInBuZyI6eyJxdWFsaXR5IjoxfX0sIm91dHB1dEZvcm1hdCI6IndlYnAifQ==',
            icons: {
                mdiClose
            },
        };
    },
    computed: {
    },
    mounted () {
        this.showNotice()
    },
    methods: {
        toPro () {
            window.open(`https://onlibox.com/r/onlibox?utm_source=${window.location.host}`)
        },
        showNotice () {
            let flag = localStorage.getItem(this.$store.state.config.headerNoticeConfig.key)
            let showNotice = flag != '1'
            this.$store.state.config.headerNoticeConfig.show = true
            return showNotice
        },
        readNotice () {
            localStorage.setItem(this.$store.state.config.headerNoticeConfig.key, '1')
            this.$store.state.config.headerNoticeConfig.show = false
        },
    },
};
</script>

<style scoped lang="scss">
.sticky-navbar {
    height: calc($header-notice-height + env(safe-area-inset-top, 0));
    width: 100%;
    color: #ffffff;
    position: relative;
    transition: background-color 0.3s;
    width: 100%;
    font-size: 16px;

    background-color: #7209b7;
    background-image: linear-gradient(270deg, #AE36FF 31%, #1c69db 100%);


    //background: linear-gradient(90deg, rgb(32, 129, 226) 0.5%, rgb(93, 50, 233) 99.74%);
    padding: 0 70px;
    position: fixed;
    top: 0;
    z-index: 200;
    .sticky-navbar-text {
        padding: 0 16px;
    }
    .sticky-navbar-target-btn {
        margin-left: 16px;
        width: 1rem;
        color: var(--v-white-base)!important;
    }
    .sticky-navbar-logo {
        width: 8rem;
    }
    .close-icon {
        position: absolute;
        right: 32px;
        color: #FFF;
        cursor: pointer;
        border-radius: 10px;
        padding: 4px;
        top: 0;
        bottom: 0;
        margin: auto;
        @media (any-hover: hover) {
            &:hover {
                background-color: #00000033;
                transform: scale(1.01);
            }
        }
      }
}


.content {
    margin-top: 60px;
    /* Adjust margin-top to accommodate the sticky navbar's height */
}

.content-item {
    margin: 20px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width:1200px) {
    .sticky-navbar {
        //padding: 0 16px;
        padding-top: env(safe-area-inset-top, 0);
        .close-icon {
            right: 16px;
            top: env(safe-area-inset-top, 0);
        }
        .sticky-navbar-target-btn {
            margin-left: 8px;
        }
        .sticky-navbar-text {
            font-size: 14px;
        }
    }
}
</style>

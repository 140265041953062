<template>
  <div class="channel-item" :class="{ online }">
    <v-list-item :key="id">
      <v-list-item-avatar @click.stop="()=>$emit('clickAvatar')">
        <v-avatar size="2rem">
          <img :src="avatar" alt="avatar">
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="channel-title-box">
          <div class="channel-title flex-c-sb">
            <span>{{channelName}}</span>
            <span class="channel-time" v-if="modal==='session'">
<!--              19:10-->
            </span>
          </div>
          <div class="flex-c-sb new-message" v-if="newMessage || statusDesc || statusIcon">
            <div class="poppins text-truncate">{{newMessage}}</div>
            <div>
              <span class="pr-1">{{statusDesc}}</span>
              <v-icon class="pl-1" :color="statusColor?statusColor:'primary'" size="1rem">{{selfIcon[statusIcon]}}</v-icon>
            </div>

          </div>
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-action v-if="icons && icons.length>0">
        <v-row>
          <v-col v-if="icons.includes('del')" >
            <v-icon color="primary" @click.stop="()=>$emit('clickDel')">{{selfIcon.mdiDelete}}</v-icon>
          </v-col>
          <v-col  v-if="icons.includes('chat')" >
            <v-icon color="primary" @click.stop="()=>$emit('clickChat')">{{selfIcon.mdiMessageText}}</v-icon>
          </v-col>
          <v-col  v-if="icons.includes('plus')" >
            <v-icon color="primary" @click.stop="()=>$emit('clickChat')">{{selfIcon.mdiPlus}}</v-icon>
          </v-col>
        </v-row>
      </v-list-item-action>
      <transition name="fade" mode="out-in">
        <v-badge
            v-if="unread>0"
            color="success "
            :content="unread"
            offset-y="-9"
        >
        </v-badge>
      </transition>
    </v-list-item>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { mdiMessageText,mdiDelete,mdiPlus,mdiCheck, mdiAccountClock, mdiAccountOff } from "@mdi/js";
export default {
  inheritAttrs: false,

  components: {},

  props: {

    itemHeight: String || Number,
    id: String || Number,
    nick: String,
    avatar: String,
    newMessage: String,
    lastActivity: String,
    statusIcon: String,
    statusDesc: String,
    statusColor: String,
    memberCount: String,
    unread: Number,
    chatMsgSeiId: String,
    online: Number,
    modal: {
      type: String,
      default: "friend"
    },
    icons: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    channelName: ({nick, id}) => nick ?? id,
    newMessageData: ({newMessage}) => newMessage ?? '' !== '' ? JSON.parse(newMessage) : {},
    channelActivity: ({lastActivity}) => Date.now(),
    ...mapGetters({
      singleUnreadCountList: "chat/singleUnreadCountList" || [],
    }),
    // unreadCount: ({singleUnreadCountList, chatMsgSeiId}) => {
    //   return Number(singleUnreadCountList.find(l => l.chatMsgSeiId === chatMsgSeiId)?.num || 0)
    // },
  },
  data: ()=>({
    selfIcon:{ mdiMessageText,mdiDelete,mdiCheck,mdiPlus, mdiAccountClock, mdiAccountOff }
  }),
  methods:{
  },
}
</script>

<style lang="scss" scoped>
.message_img {
  width: 18px;
  height: 18px;
}

.channel-item {
  border-radius: 0.5rem;
  cursor: pointer;
  //background: #11111120;
}

.channel-item.online {
  /*background: #f5f5f5;*/
}
.channel-title-box{
  display: flex;
  flex-wrap: wrap;
  min-height: 1.5rem;
  align-items: center;
  .channel-title{
    width: 100%;
    font-size: .8rem;
    font-weight: bold;
  }
  .channel-time {
    font-size: .5rem;
    font-family: Poppins!important;
    font-weight: 300;
    color: var(--v-primary-base);
  }
  .new-message {
    width: 100%;
    font-family: Poppins!important;
    font-weight: 500;
    color: #999999;
    font-size: 12px!important;
    letter-spacing: .0178571429em!important;
  }
}


/*.channel-item:hover {*/
/*  background: #f2f2f2;*/
/*}*/

</style>

<template>
  <div class="index_battlesList" id="index_rand_goods">
    <div class="index_area_title_row pt-4 d-flex justify-space-between align-center">
      <div :cols="device === 'PC' ? 2 : 6" class="text-left px-0">
        <div class="index_area_title_row_left">
          FEATURED ITEMS
        </div>
      </div>

      <v-btn depressed small :loading="loading" @click="getList" :height="device === 'PC' ? '2rem' : ''" class="pa-2"
        color="black">
        SHUFFLE
      </v-btn>

    </div>
    <div class="goods-card-row-wrapper">
      <swiper class="goods-card-wrapper scrollBarHidden" ref="goods-swiper" :options="swiperOptions">
        <swiper-slide v-for="(goods, i) in list" :key="i" class="slide">
          <GoodsCardNormal :share="true" @share="e=>$emit('share',e)" :shopInfo="{ goods }" :showTransform="false" class="goods-card-item shadow" />
        </swiper-slide>
      </swiper>
    </div>

    <div class="loading-block-btn-wrapper">
      <v-btn depressed block color="success" class="shadow" @click="toPage"
        v-ripple="{ class: `success--text darken-1` }">view all</v-btn>
    </div>

  </div>
</template>

<script>
import { getRandGoodsList } from "@/api/product_box";
import GoodsCardNormal from "@/components/GoodsCardNormal.vue";
import LoadingBlockBtn from "@/components/LoadingBlockBtn.vue";

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css' // 注意这里的引入

import { mdiChevronLeft, mdiChevronRight, mdiRefresh } from "@mdi/js"


export default {
  name: "IndexGoodsItemArea",
  components: {
    LoadingBlockBtn,
    GoodsCardNormal,
    Swiper,
    SwiperSlide,
  },
  props: ['device'],
  computed: {
    swiper () {
      return this.$refs['goods-swiper']?.$swiper
    },
  },
  data () {
    return {
      loading: true,
      pageNo: 0,
      list: [],

      icons: {
        mdiChevronLeft, mdiChevronRight,
        mdiRefresh,
      },
      swiperOptions: {
        allowSlidePrev: false,
        slidesPerView: 2,
        slidesPerColumnFill: 'row',
        slidesPerColumn: window.innerWidth > 768 ? 1 : 2,
        spaceBetween: 16,
        slidesPerGroup: 2,
        breakpoints: {
          1024: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 16
          },
          768: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 8
          },
        },
      },
    }
  },
  created () {
    //初始化数据
    this.getList();
  },
  methods: {

    getList () {
      this.loading = true
      let pageSize = window.innerWidth > 768 ? 10 : 8

      getRandGoodsList({ pageSize: pageSize, pageNo: this.pageNo }).then(res => {

        this.list.push(...res?.data || [])
        this.swiper.update()
        if (this.pageNo > 0) {
          this.$nextTick(() => {
            this.swiper.slideNext()
          })
        }
        this.pageNo++
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 1000)

      })
    },
    toPage () {
      this.$router.push({
        path: "/archive"
      })
    }
  }
}
</script>

<style scoped lang="scss">
.index_battlesList {
  padding-left: 0!important;
  padding-right: 0!important;
  .index_area_title_row {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
}
.loading-block-btn-wrapper {
  padding: 16px;
}
$width: auto;
$aspectRatio: calc(750 / 1050);
.goods-card-row-wrapper {
  position: relative;
  min-height: calc( $width / $aspectRatio + 32px);
  margin: 0;

}
.goods-card-wrapper {
  position: relative;
  padding: 16px;

  .goods-card-item {
    border-radius: 12px;
    width: $width;
    //height: calc( $width / $aspectRatio);
    transition: .3s cubic-bezier(.25, .8, .5, 1);
    @media (any-hover: hover) {
      &:hover {
        transform:  translateY(-6px);
      }
    }
  }
}

@media (max-width:1200px) {
  .index_battlesList {
    .index_area_title_row {
      padding-bottom: 0 !important;
    }
  }
}

</style>

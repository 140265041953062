<template>
  <transition name="fade-slide" mode="out-in">

    <div class="link-wrapper">
      <div class="link-item">
        <v-img class="pointer link-image hover-scale"  :height="device == 'PC' ? 122.5 : 100"
          :src="$S3Utils.toWebp(item.bannerImageUrl)" @click="toPro"></v-img>
        <v-icon class="close-icon" @click="$emit('close')">{{ icons.mdiClose }}</v-icon>
      </div>
    </div>
  </transition>
</template>

<script>


import { mdiClose } from "@mdi/js"

export default {
  name: "IndexBannerProLinkArea",
  props: {
    configList: {
      type: Object,
      default: () => {
      }
    },
    device: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    icons: {
      mdiClose
    },
    item: {
      // bannerImageUrl: 'https://onliboxs3.s3.us-west-1.amazonaws.com/public/base_data/banner-pro-link-1.png',
      bannerImageUrl: 'https://onliboxs3.s3.us-west-1.amazonaws.com/public/base_data/banner-pro-link-3.png',
      jumpUrl: '/r/onlibox',
    },
  }),
  methods: {
    toPro () {
      window.open(`https://onlibox.com/r/onlibox?utm_source=${window.location.host}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-slide-enter-active, .fade-slide-leave-active {
  transition: all 3s;
}
.fade-slide-enter, .fade-slide-leave-to {
  transform: translateY(-400px);
}
@media (max-width: 1200px) {
  .fade-slide-enter {
    transform: translate(-800px,0);
  }
   .fade-slide-leave-to {
    transform: translate(800px,0);
  }
}
.link-wrapper {
  position: relative;
  padding: 1rem 1rem .5rem;
  transition: .3s cubic-bezier(.25, .8, .5, 1);
  min-height: 90px;

  //.link-item {
  //  background-color: var(--v-boxCardBg-base);
  //  border-radius: 10px;
  //}
  .link-image {
    border-radius: 10px;
    //width: 100%;
    &:hover {
      transform: scale(1.01);
    }
  }
  .close-icon {
    position: absolute;
    right: 32px;
    top: 32px;
    color: #FFF;
    cursor: pointer;
    background-color: #00000033;
    border-radius: 10px;
    padding: 4px;

  }
}
@media (max-width:1200px) {
  .link-wrapper {
    padding: 0.25rem 0.25rem 0;

    .close-icon {
      right: 16px;
      top: 12px;
      width: 28px;
      height: 28px;
      border-radius: 7px;

    }
  }
}
</style>

<template>
  <div class="itemCard z-2">
    <div class="item" v-if="shopInfo.goods">
      <div class="item_img d-flex align-center" @mouseenter="showisView" @mouseleave="hideView" :style="{
        backgroundColor: bgcolor[dayStatus][shopInfo.goods.level],
      }">
      <!-- <div v-lazy:background-image="{
        src: $S3Utils.resizeGoodsPictureLazy(shopInfo.goods.picture),
        
      }"  class="item_img_item"></div> -->
      <div v-lazy-container="{ selector: 'img' }" text-center>
          <img 
          :data-src="$S3Utils.toWebp(shopInfo.goods.picture)" 
          :data-loading="$S3Utils.resizeGoodsPictureLazy(shopInfo.goods.picture)" 
          class="item_img_item" 
          width="85%" :alt="`onlibox ${shopInfo.goods.title}`">
      </div>
        

      <!-- <img 
      :loading="$S3Utils.resizeGoodsPictureLazy(shopInfo.goods.picture)" class="item_img_item"> -->

        <!-- <v-img :lazy-src="$S3Utils.resizeGoodsPictureLazy(shopInfo.goods.picture)"
          :src="$S3Utils.toWebp(shopInfo.goods.picture)" width="85%" class="item_img_item" contain 
          alt="box-image" :class="{
            animata_img: isView,
          }"></v-img> -->
        <div class="img_hoverPop  flex allcenter" v-show="isView">
          <icon v-if="!showGoodsPictureMasks" alt="view" class="icon-view pointer" className="icon-view" name="icon-view"
            @click="setOpen"></icon>
        </div>
        <div class="img_hoverPop flex allcenter" v-if="showGoodsPictureMasks">
          <div class="lock-icon-wrapper" @click="setOpen">
            <v-icon size="50" class="icon-view" color="#FFF">{{ icons.mdiLock }}</v-icon>
          </div>
        </div>
        <GoodsDetailHover v-model="viewInfo" :goodsInfo="shopInfo.goods" :goodsId="shopInfo.goods.id"></GoodsDetailHover>
      </div>
      <div class="item_info cflex around">
        <div class="info_title single-line-text" v-if="shopInfo.goods.title">
          {{ shopInfo.goods.title }}
        </div>
        <div class="info_desc">
          <!-- <span
            v-for="(item, index) in shopInfo.goods.tagList"
            :key="item.dataId"
            >{{ item.tagName }}
            <span v-if="index < shopInfo.goods.tagList.length - 1">,</span>
          </span> -->
        </div>
        <div class="info_price flex rcenter between">
          <span>${{ (shopInfo.goods.price / 100) | matterPriceDecimal }}</span>
          <v-img alt="view" src="/assets/img/split/info-icon.png" width="12" height="12" style="flex: 0 0 auto"
            @click="showVariants = true"></v-img>
        </div>
      </div>
      <div class="item_position d-flex" :class="shopInfo.odds ? 'justify-space-between' : 'justify-end'">
        <span class="small-tag-span" v-if="shopInfo.odds">{{ shopInfo.odds || 0 }}%</span>

        <template v-if="share">
          <v-progress-circular class="mr-2" indeterminate width="2" v-if="shareLoading" color="white"
            size="14"></v-progress-circular>
          <v-icon size="0.75rem" class="icon-view" color="#FFF" @click="clickShare">{{ icons.mdiOpenInNew
          }}</v-icon>
        </template>
      </div>
      <div class="position_info" v-show="showVariants">

        <variants-card v-show="showVariants" @close="closeVar" :variantsInfo="shopInfo.goods.goodsSkuList"
          :odds="shopInfo.odds"></variants-card>
      </div>
    </div>
  </div>
</template>

<script>
import bgcolor from "@/utils/goodsLevelColor2.js";
import GoodsDetailHover from "@/components/GoodsDetailHover.vue";
import variantsCard from "@/views/splitInfo/components/variantsCard.vue";
import { mapState } from "vuex";
import { mdiLock, mdiOpenInNew } from "@mdi/js"
import { getGoodsInfo } from "@/api/product_box.js";

export default {
  name: "GoodsItemCard",
  props: {
    showGoodsPictureMasks: false,
    share: false,
    shopInfo: {
      default: () => {
        return {
          goods: {
            title: "",
            price: "",
            picture: "",
            odds: 0,
          },
        };
      },
    },
    device: {
      default: "PC",
    },
  },
  components: {
    GoodsDetailHover,
    variantsCard,
  },
  computed: {
    ...mapState({ dayStatus: (state) => state.user.dark })
  },
  data () {
    return {
      icons: { mdiLock, mdiOpenInNew },
      //
      isView: false,
      //显示详细信息
      showVariants: false,
      viewInfo: false,
      // dayStatus: "",
      bgcolor,
      shareLoading: false,
    };
  },
  mounted () { },

  methods: {
    clickShare () {
      if (this.shareLoading) {
        return
      }
      this.shareLoading = true
      let goodsId = this.shopInfo.goods.id
      getGoodsInfo({
        goodsId,
      }).then(res => {
        let data = res.data
        data.goodsBoxList = data?.goodsBoxList?.sort((a, b) => b.odds - a.odds) || [];
        let boxCode = data.goodsBoxList[0]?.boxCode
        let params = { boxCode, goodsId }
        this.$emit('share', params)
      }).finally(() => {
        this.shareLoading = false
      })
    },
    setOpen () {
      this.isView = false;
      if (this.shopInfo.goods.isRec) {
        sessionStorage.setItem("Recdata", JSON.stringify(this.shopInfo));
        this.$router.push(
          `/boxinfo/${this.shopInfo.goods.oddsHeightBoxId}?isRec=true`
        );
        return;
      }
      this.viewInfo = true;
    },
    showisView () {

      this.isView = true;
    },
    hideView () {

      this.isView = false;
    },
    closeVar () {
      this.showVariants = false;
    },
    closeView () {
      this.viewInfo = false;
    },
  },
};
</script>

<style lang="scss" scoped>


::v-deep .v-image__image--preload{
  filter: blur(4px);
}
.itemCard {
  border-radius: 12px;
  .item {

    
    
    transition: box-shadow 0.25s ease-in-out 0s, transform 0.25s ease 0s;

    cursor: pointer;
    //width: 100%;
    
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    // height: 357px;
    background-color: var(--v-boxCardBg-base);
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    .item_img {
      width: 100%;
      padding: 2rem;
      flex-grow: 1;
      box-sizing: border-box;
      height: 100%;
      //background-color: #fff;
      position: relative;
      overflow: hidden;
      .item_img_item {
        //height: 10rem;
        height: 100%;
      }
      .animata_img {
        transition: all 0.5s;
      }
      .img_hoverPop {
        position: absolute;
        transition: all 0.5s;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        .icon-view {
          width: 40%;
          height: 40%;
        }
      }
    }
    .item_info {
      //width: 100%;
      //height: 30%;
      padding: 12px;
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
      text-align: left;
      //background-color: #FFF;
      .info_title {
        width: 100%;
        min-height: 30px;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        //display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .info_desc {
        font-weight: 500;
        color: #999999;
        line-height: 12px;
        margin: 6px 0 9px;
      }
    }
    .item_position {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 8px;

      box-sizing: border-box;
      span {
        font-size: 12px;
        font-family: Poppins;
        font-weight: 700;
        line-height: 13px;
      }
    }
    .position_info {
      position: absolute;
      top: 0;
      left: 0;
      //z-index: 600;
      width: 100%;
      height: 100%;
    }
  }

}
.lock-icon-wrapper {
  backdrop-filter: blur(6px);
  box-shadow: -20px 0px 20px 0px #2f2b2b73;
  background-color: #1c69db80;
  width: 130px;
  height: 130px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width:1200px) {
  .itemCard {
    .item {
      height: 15rem;
    .item_img {
      //padding: 2rem;
      .item_img_item {
        //height: 10rem;
      }
    }
  }
  }
}
</style>

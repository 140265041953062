<template>
  <div>
    <div>

      <v-dialog content-class="v-dialog-right" v-model="value"  @click:outside="close" persistent scrollable  min-height="90%">
        <v-card :elevation="0" rounded="0" color="dialog">
          <v-card-title class="xs:important-px-6 xs:important-py-4 important-font-bold">
            <div class="d-flex justify-space-between align-center w-full">
              <div>
                <span>FRIENDS</span>
              </div>
              <icon class-name="pointer v-dialog-close" name="dialog-close" width="25px" height="25px" @click="close">
              </icon>


            </div>

          </v-card-title>



          <v-card-text class="cart_order font-weight-bold black--text pa-0  white">

            <FriendChannelList v-if="value" @total="(t) => total = t" modal="friend" @clickChannel="toChat" height="350"
              :icons="['chat']" :itemHeight="itemHeight" />

          </v-card-text>

          <v-card-actions class="xs:important-px-6 xs:important-py-4">
            <div class="box_title d-flex justify-space-between align-center w-full">
              <v-btn  class="" height="2.5rem" x-small :ripple="false" color="black" @click="toMyFriend">
                <div class="text-center">
                  <span>VIEW ALL </span>
                  <span style="width: fit-content;background-color:#FFF;color:#000" class="px-1 mr-1 rounded">{{ total
                  }}</span>
                  <span> FRIENDS</span>
                </div>
              </v-btn>
            </div>

          </v-card-actions>

        </v-card>

      </v-dialog>

    </div>

  </div>
</template>

<script>
import FriendChannelList from "./FriendChannelList.vue";
import { mdiAccountSupervisor } from "@mdi/js";

export default {
  name: "msgBox",
  props: ["showMessage", "device", "value"],
  components: {
    FriendChannelList
  },
  data () {
    return {
      total: 0,
      itemHeight: '70',
      icons: {
        mdiAccountSupervisor
      }
    };
  },
  created () {
  },
  mounted () {
  },
  methods: {
    stopScroll (e) {
      e.preventDefault();
      e.stopPropagation();
    },
    close () {
      this.$emit("input", false);
    },
    toMyFriend () {
      this.$router.push("/userinfo?nowShow=MyFriend");
    },
    async toChat (userInfo) {
      this.$bus.$emit("toChat", { userId: userInfo.friendUserId });
    },
  },
};
</script>

<style lang="scss" scoped>
.message_box {

  transition: all 0.5s;
  width: 420px;
  min-width: 320PX;
  height: 31.25rem /* 500/16 */;
  box-shadow: 0px 3.9px 7.8px 0px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: $header-menu-height;
  right: 0px;
  z-index: 1500;
  box-sizing: border-box;
  padding: 16px 0 10px;
  font-size: 12px;
  overflow: hidden;
  background-color: var(--v-white_night-base);
  border-radius: 0 0 10px 10px;

  //.box_title {
  //  width: 100%;
  //  padding: 21px 0;
  //  font-size: 16px;
  //
  //  font-weight: 600;
  //  color: #000000;
  //  line-height: 17px;
  //  border-bottom: 1px solid #f0f0f0;
  //}

  .box_content {
    width: 100%;
    flex: 1 !important;
    //overflow-y: scroll;
    // list 350px + see all 48px + 20px*2
    height: 420px;
    padding: 0px 15px 0 20px;
    box-sizing: border-box;

  }

  .box_content::-webkit-scrollbar {
    height: 0 !important;
    width: 0rem !important;
  }

  .box_content_model {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.message_box_model {
  top: $header-menu-height;
  left: 0;
  width: 100%;
  height: calc(100% - $header-menu-height);
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background-color: transparent;
}

.total-box {
  border-radius: 10px;
  max-height: 3.75rem /* 60/16 */;
  // margin-bottom: 1rem;
  width: 100%;
  background-color: var(--v-grey_grey02-base);
  box-sizing: border-box;
  cursor: pointer;



  .cleft_info {
    .tit {
      font-weight: 700;
      font-size: 15px;
      line-height: 1.5;
      color: var(--v-black-base);
    }
    .desc {
      font-size: 12px;
      font-weight: 500;
      color: var(--v-primary-base);
    }
  }

  .title_right {
    .right_img {
      width: 14px;
      height: 14px;
    }
  }
}
</style>

import request from '@/utils/request'

// 获得盲盒分页
export function boxPage(query) {
  return request({
    url: '/app-api/product/box/page',
    method: 'GET',
    params: query
  })
}
//获取盲盒信息
export function getBoxInfo(query) {
  return request({
    url: '/app-api/product/box/get',
    method: 'GET',
    params: query
  })
}
export function getBoxInfoByCode(boxCode) {
  return request({
    url: `/app-api/product/box/get/${boxCode}`,
    method: 'GET',
  })
}
//获取盲盒 goods list
export function getBoxGoodsListByCode(boxCode) {
  return request({
    url: `/app-api/product/box/goods/${boxCode}`,
    method: 'GET',
  })
}
//获取盲盒 goods list
export function getBoxGoodsList(query) {
  return request({
    url: '/app-api/product/box/goods',
    method: 'GET',
    params: query
  })
}
// 获取匿名用户盒子 盒子String(ID) !=0
export function getAnonymityUsersBox(query) {
  return request({
    url: '/app-api/product/box/anonymity',
    method: 'GET',
    params: query
  })
}
// 获取匿名用户盒子 盒子String(ID) !=0
export function getAnonymityUsersBoxFinalCode(query) {
  return request({
    url: '/app-api/product/box/anonymityCode',
    method: 'GET',
    params: query
  })
}
//拆开个盲盒
export function getMOpenBox(data) {
  return request({
    url: '/app-api/game/unboxing',
    method: 'post',
    data
  })
}
// // 拆开盲盒的详细信息
// export function getUnboxingHistory(data) {
//   return request({
//     url: '/app-api/game/unboxing/history',
//     method: 'get',
//     params: data
//   })
// }
// 拆开盲盒的详细信息
export function getUnboxingHistoryByCardNo(data) {
  return request({
    url: '/app-api/game/unboxing/history/card',
    method: 'get',
    params: data
  })
}
export function getUnboxingHistoryByHashId(data) {
  return request({
    url: '/app-api/game/unboxing/history/hash',
    method: 'get',
    params: data
  })
}
//拆开n个盲盒
export function getMOpenBoxAssembly(data) {
  return request({
    url: '/app-api/game/unboxing/assembly',
    method: 'post',
    data
  })
}
//拆开个盲盒
export function getUnboxingHash(hashId) {
  return request({
    url: '/app-api/game/unboxing/hash',
    method: 'post',
    params: {hashId}
  })
}
//拆开个盲盒
export function getMOpenBoxAnonymity(data) {
  return request({
    url: '/app-api/game/unboxing/anonymity',
    method: 'post',
    params: data
  })
}

/**
 * 返回是否达到指定次数可以进入bingo
 * 返回盒子概率 打开次数
 * @param params
 * @returns {*}
 */
export function getUserBoxBingoInfo(params) {
  return request({
    url: '/app-api/game/unboxing/bingo/info',
    method: 'get',
    params
  })
}
/**
 * 返回是否达到指定次数可以进入bingo
 * 返回盒子概率 打开次数
 * @param params
 * @returns {*}
 */
export function getGlobalBingoCount() {
  return request({
    url: '/app-api/game/unboxing/bingo/drop',
    method: 'get',

  })
}

//获取已拆盲盒分页
export function getIsOpenBox(query) {
  return request({
    url: '/app-api/game/unboxing/page',
    method: 'GET',
    params: query
  })
}
//获取免费掉落盒子
export function getFreeBoxList(params) {
  return request({
    url: '/app-api/product/box/freeDrop',
    method: 'GET',
    params
  })
}
//认领免费盒子推荐码
export function getFreeBox(data) {
  return request({
    url: '/app-api/commission/account/claim/referral/code',
    method: 'PUT',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data
  })
}

//获取当前盒子中盒子推荐列表
export function getRecBox(data) {
  return request({
    url: '/app-api/product/box/recommend?boxId=' + data,
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  })
}
//获取当前盒子中盒子推荐列表
export function getRecBoxByBoxCode(boxCode){
  return request({
    url: `/app-api/product/box/recommend/${boxCode}`,
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  })
}
//get rand {pageSize}
export function getRandGoodsList(params) {
  return request({
    url: '/app-api/product/goods-spu/list/random',
    params,
    method: 'GET',
  })
}
// 获得商品详情 goodsId boxId
export function foodInfo(query) {
  return request({
    url: '/app-api/product/goods-spu/get',
    method: 'GET',
    params: query
  })
}
// 获得商品详情 goodsId
export function getGoodsInfo(query) {
  return request({
    url: '/app-api/product/goods-spu/info',
    method: 'GET',
    params: query
  })
}
// 获得商品详情 goodsId
export function getGoodsInfoBoxList(query) {
  return request({
    url: '/app-api/product/goods-spu/info/box',
    method: 'GET',
    params: query
  })
}

//获取当前盒子中商品推荐列表
export function getRecShop(data) {
  return request({
    url: '/app-api/product/goods-spu/recommend?boxId=' + data,
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  })
}
//获取当前盒子中商品推荐列表
export function getRecShopByBoxCode(boxCode) {
  return request({
    url: `/app-api/product/goods-spu/recommend/${boxCode}`,
    method: 'GET',
  })
}
// 用于app根据标签筛选 classDictValue可以为空
export function getTagMapListByClassDict(classDictValue) {
  return request({
    url: `/app-api/product/tags/class-tags`,
    method: 'GET',
  })
}
export function getTagListByClassDict(classDictValue) {
  return request({
    url: `/app-api/product/tags/list`,
    method: 'GET',
  })
}
export function getTagClassList(classDictValue) {
  return request({
    url: `/app-api/product/tags/class`,
    method: 'GET',
  })
}

<template>
  <div class="main" id="home-wrapper">

    <div class="index_content flex between">
      <!-- scrollBarHidden -->
      <div class="content_left">
        <div class="content_left_body">
          <!-- <vue-lazy-component :timeout="600"> -->
            <div class="" v-if="$breakpoint().le('md')">
                <right-shop-box class="index-livedrop" style="width: 100%"></right-shop-box>
              </div>
          <!-- </vue-lazy-component> -->

          <v-scroll-y-transition :group="false">

            <IndexBannerArea class="index-banner-area" key="IndexBannerArea" :device="device" :configList="configList" />

          </v-scroll-y-transition>


          <!-- 盒子卡片区 -->
          <IndexBoxArea :device="device" @share="copyShareBoxUrl"/>
          <!-- <v-lazy :options="{
          threshold: .5
        }" min-height="300" transition="fade-transition"> -->
          <IndexRandGoods :device="device" @share="copyShareBoxUrl"/>
          <!-- </v-lazy> -->

          <!-- 对战区 -->
          <!-- <v-lazy :options="{
          threshold: .5
        }" min-height="600" transition="fade-transition"> -->
          <IndexClashArea :device="device" @share="copyShareBoxUrl"/>
          <!-- </v-lazy> -->
        </div>
        <!-- 描述区 -->

        <!-- <v-lazy :options="{
          threshold: .5
        }" min-height="120" transition="scroll-x-transition"> -->
        <IndexFootDescArea :device="device" :config-list="configList" />
        <!-- </v-lazy> -->
      </div>
    </div>

<!--    <vue-lazy-component :timeout="1400">-->
<!--      <WelcomeModal v-model="welcomeModalShow" />-->
<!--    </vue-lazy-component>-->
  </div>
</template>

<script>
import rightShopBox from "@/components/rightShopBox.vue";

import { mapState } from "vuex";
import IndexBoxArea from "@/views/index/IndexBoxArea.vue";
// import IndexBoxAreaHub from "@/views/index/IndexBoxAreaHub.vue";
import IndexClashArea from "@/views/index/IndexClashArea.vue";
import IndexBannerArea from "@/views/index/IndexBannerArea.vue";
import IndexRandGoods from "@/views/index/IndexRandGoods.vue";
import IndexBannerProLinkArea from "@/views/index/IndexBannerProLinkArea.vue";
import IndexFootDescArea from "./IndexFootDescArea.vue";
import { startShepherdNewUser } from "@/plugins/shepherd";
// import WelcomeModal from './WelcomeModal.vue'
import { debounce } from "@/utils/functions";
export default {
  name: "index",
  components: {
    rightShopBox,
    IndexBoxArea,
    IndexBannerArea,
    IndexRandGoods,
    IndexBannerProLinkArea,
    IndexClashArea,
    IndexFootDescArea,
    // WelcomeModal,
  },
  props: ["device"],
  data () {
    return {
      welcomeModalShow: false,
      selectAll: true,
      functionList: [
        "UNBOXING",
        "CLASHES",
        "AFFBJATES",
        "FREE DROP",
        "HOW IT WORKS",
      ],


      // comDescript:
      //     "Check out some of the hottest unboxings from our community! To appear on our page,tag",

    };
  },
  computed: {
    ...mapState({ configList: (state) => state.config.configList }),
  },
  created () {
    if (!this.$store.state.user?.userId) {
      setTimeout(() => {
        this.welcomeModalShow = true
      }, 2000)
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll, true)
    startShepherdNewUser()
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll, true);
  },
  methods: {
    copyShareBoxUrl (params) {
      let referralCode = this.$store.state.user?.commissionInfo?.referralCode || 'onlibox'
      let url = `${window.location.origin}/r/${referralCode}?`

      if (params?.boxCode) {
        url = `${url}&boxCode=${params.boxCode}`
        this.$gtagEvent('ob_click', 'share', `box_${params.boxCode}`)
      }
      if (params?.goodsId) {
        url = `${url}&goodsId=${params.goodsId}`
        this.$gtagEvent('ob_click', 'share', `goods_${params.goodsId}`)
      }
      if (params?.clashId) {
        url = `${url}&clashId=${params.clashId}&replay=0`
        this.$gtagEvent('ob_click', 'share', `clash_${params.clashId}`)
      }
      this.$clipboard(url)
      this.$awn.success("Share Link Copied!")

    },
    onScroll (e) {
      let scrollTop = window.pageYOffset
      if (scrollTop == 0) {
        return
      }
      debounce(() => {
        Array.of(...document.querySelectorAll(".index_area_title_row")).forEach(dom => {
          let offset = Math.abs(scrollTop - dom.offsetTop)

          if (scrollTop <= dom.offsetTop && offset <= 200) {
            let targetOffset = dom.offsetTop
            // 导航栏高度
            if (window.innerWidth < 1024) {
              targetOffset -= document.getElementById("header-row-wrapper").clientHeight
            }
            window.scrollTo({
              top: targetOffset - 16,
              behavior: "smooth"
            })
          }
        })
      }, 600)
    }
  }
};
// Start the introduction

</script>

<style lang="scss">
/* 板块标题 */
.index_area_title_row {
  font-size: 24PX;
  font-weight: bold;
  margin: 0;
  .index_area_title_row_left {
    cursor: pointer;
  }
  .index_area_title_row_left_active {
    display: inline;
    position: relative;
    &:before {
      position: absolute;
      content: " ";
      left: 0;
      bottom: -8px;
      right: 0;
      width: 50%;
      margin: auto;
      border-bottom: 4px solid var(--v-black-base);
    }
  }
  .index_area_title_row_right {
    font-size: 14PX;
    cursor: pointer;
    color: var(--v-primary-base);
  }

}
.main {
  width: 100%;
  background-color: var(--v-rightBg-base);
  font-weight: 500;
  scroll-behavior: 'smooth';


  .index-livedrop {
  }

  .index_content {
    width: 100%;

    .content_left {
      width: 100%;

      .content_left_body {
        // 70-16
        padding: 0 54px;
      }
      .index_battlesList {
        width: 100%;
        padding: 0 1rem 4rem;
        box-sizing: border-box;

      }
    }

  }
}

@media  (max-width: 1024px) {
  .main {
    //padding-top: $header-menu-height;
    .content_left {
      .content_left_body {
        padding: 0 !important;
      }
    }
  }
  .index_area_title_row {
    font-size: 20PX;
  }
}

</style>

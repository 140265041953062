<template>
  <div class="card_box z-2 cflex rcenter" @click.stop="toSplitInfo" v-ripple="{class:'rightBg--text'}">
    <div class="box_title d-flex justify-space-between align-start">
      <div class="d-flex justify-start">
        <template v-if="boxInfo?.hubSign">
          <div class="box_title_label limited flex" :style="boxInfo?.bg">
            LIMITED
          </div>
        </template>
        <template v-else>
          <div class="box_title_label featbox" v-if="boxInfo && boxInfo?.featuredSign && boxInfo.featuredSign === 1">FEATURED
          </div>
          <div class="box_title_label newbox flex" v-if="boxInfo && boxInfo?.newsSign && boxInfo.newsSign === 1">NEW</div>
          <div class="box_title_label updatabox flex" v-if="boxInfo && boxInfo?.updatedSign && boxInfo.updatedSign === 1">
            UPDATED
          </div>
        </template>
      </div>
      <div>
        <v-icon v-show="!skeleton" size="0.75rem" class="icon-view" color="black" @click.stop="clickShare">{{ icons.mdiOpenInNew }}</v-icon>
      </div>
    </div>
    <!-- @mouseenter="
        isView = true;
        isLeave = false;
      "
      @mouseleave="mouseLe" -->

    <v-skeleton-loader v-if="skeleton" boilerplate class="mb-3" height="100%" width="100%" type="image"></v-skeleton-loader>
    <div class="box_img d-flex" v-if="boxInfo?.boxPicture">
      <!-- <v-img :alt="boxInfo?.title ?? boxInfo?.boxPicture" :src="$S3Utils.resizeBoxPicture(boxInfo && boxInfo?.boxPicture)"
        :lazy-src="$S3Utils.resizeBoxPictureLazy(boxInfo && boxInfo?.boxPicture)" class="box_img_item"
        :class="{ img: true, img_active: isView, img_active_leave: isLeave }" contain></v-img> -->
      <div v-lazy-container="{ selector: 'img' }" flex items-center justify-center>
          <img
          :data-src="$S3Utils.resizeBoxPicture(boxInfo && boxInfo?.boxPicture)" 
          :data-loading="$S3Utils.resizeBoxPictureLazy(boxInfo && boxInfo?.boxPicture)" 
          class="box_img_item w-full"
          :class="{ img: true, img_active: isView, img_active_leave: isLeave }" 
          :alt="`unbox ${boxInfo?.title}`">
      </div>
      <!-- <v-slide-y-transition mode="in-out">
        <div class="img_hoverPop flex allcenter" v-show="isView">
          <icon width="40%" height="40%"
              class="icon-view"
              className="icon-view"
              name="icon-view"
              @click="viewInfo = true"
          ></icon>
        </div>
      </v-slide-y-transition> -->
    </div>
    <div class="box_info cflex between">
      <div class="box_name">
        {{ boxInfo?.title }}
        <v-skeleton-loader boilerplate v-if="skeleton" class="" height="100%" width="60%" type="text"></v-skeleton-loader>
      </div>
      <div class="box_des">
        <v-skeleton-loader boilerplate v-if="skeleton" class="" height="100%" width="30%" type="text"></v-skeleton-loader>
        <template v-else>
          <template v-if="boxInfo && boxInfo.tags && boxInfo.tags.length > 0">
            {{ boxInfo?.tags.toString() }}
          </template>
          <template v-else>
            No category
          </template>
        </template>
      </div>
      <div class="box_price flex-c-sb">
        <v-skeleton-loader boilerplate v-if="skeleton" class="" height="100%" width="15%" type="text"></v-skeleton-loader>
        <template v-else>
          ${{ ((boxInfo?.boxPrice || 0) / 100) | matterPriceDecimal }}

          <v-progress-circular v-if="boxInfo?.goodsCount && boxInfo?.myGoodsCount"
                               color="success" size="16" width="2"
                               :rotate="-90"
                               :value="boxInfo?.myGoodsCount/boxInfo?.goodsCount*100"></v-progress-circular>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiOpenInNew } from "@mdi/js"
export default {
  name: "boxCard",
  props: {
    boxInfo: {
      typeof: Object,
      default: () => { },
    },
    device: {
      default: "PC",
    },
    isState: {
      default: false,
    },
    skeleton: {
      default: false,
    },
  },
  data () {
    return {
      icons: { mdiOpenInNew },
      isView: false,
      isLeave: false,
    };
  },

  mounted () {

  },

  methods: {
    clickShare () {
      if (this.skeleton == true) {
        return
      }
      let referralCode = this.$store.state.user?.commissionInfo?.referralCode || 'onlibox'
      let url = `${window.location.origin}/r/${referralCode}?`
      url = `${url}&boxCode=${this.boxInfo.code}`
      this.$clipboard(url)
      this.$awn.success("Share Link Copied!")

      this.$gtagEvent('ob_click', 'share', `box_${this.boxInfo.code}`)
    },
    toSplitInfo () {
      if (this.skeleton == true) {
        return
      }
      let item = this.boxInfo
      if (this.isState) {
        this.$router.push({
          name: "boxinfo",
          params: { id: this.boxInfo.id },
          query: { isState: this.isState },
        });
      } else {
        if (item?.code) {
          this.$router.push(`/box/${item.code}`);
        } else {
          this.$router.push(`/boxinfo/${item.boxId}`);
        }
      }
    },
    enter () {

    },
    mouseLe () {
      this.isLeave = true;
      this.isView = false;
      setTimeout(() => {
        this.isLeave = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.card_box {
  max-width: 330px;
  padding: 0 0 12px;
  box-sizing: border-box;
  background-color: var(--v-boxCardBg-base);
  border-radius: 10px;
  //margin: 0 0 12.48px 0;
  cursor: pointer;
  overflow: visible;

  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  .box_title {
    font-size: 12px;
    width: calc(100% - 12px * 2);
    //line-height: 14px;
    //height: 14px;
    align-self: flex-start;
    //z-index: 1;
    position: absolute;
    top: 12px;
    left: 12px;

    .box_title_label {
      color: #fff;
      padding: 6px;
      font-size: 0.5rem;
      font-weight: 700;
      margin-right: 0.5rem;
      border-radius: 2px;

    }
    @media (min-width: 320px) and (max-width: 640px) {
      .box_title {
        border-radius: 0;
      }
      .box_title_label {
        padding: 4px!important;
        font-size: 0.4rem;
        font-weight: 700;
        margin-right: 0.25rem;
      }
    }
    & > .tab_model {
    }
    .newbox {
      background-color: #30bf68;
    }
    .featbox {
      background-color: var(--v-success-base);
    }
    .updatabox {
      background-color: #f3b40b;
    }
    .limited {
      background-color: #ffa31a;
    }
    .hotbox {
      background-color: #bf080f;
    }
  }
  .box_img {
    padding: 12px 12px 0;
    width: 70%;
    flex-grow: 1;
    //height: 176px;
    //height: 14rem;
    //margin-bottom: 50px;
    position: relative;
    //margin-top: 1vh;
    //margin-bottom: 1vh;
    transition: .3s cubic-bezier(.25, .8, .5, 1);
    will-change: transform;
    @media (any-hover: hover) {
      &:hover {
        transform: translateY(-12.5%) scale(1.15);
      }
    }

    .img_hoverPop {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .box_img_model {
    width: 100%;
    height: 9rem;
    //margin-bottom: 26px;
  }
  .box_info {
    padding: 0 12px;
    width: 100%;
    text-align: left;
    color: var(--v-black-base);
    font-size: 0.625rem;
    font-weight: 700;
    .box_name {
      width: 100%;
      overflow: hidden;
      padding: 2px 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1rem;
      line-height: 1;
    }
    .box_des {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #999999;
      line-height: 1;
      padding: 4px 0;
    }
    .box_price {
      font-size: 0.75rem;
    }
  }
}

@media (max-width:1024px) {

  .card_box {
    height: 15rem;
    .box_info {
      .box_name {
        font-size: 14px;
      }
      .box_des {
        font-size: 12px;
      }

    }
  }
}
</style>

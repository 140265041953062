import Vue from 'vue'
import Vuetify from 'vuetify'

import 'vuetify/dist/vuetify.min.css'
import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition'
const myTransition = createSimpleTransition('my-transition')

import store from '@/store'
Vue.use(Vuetify)
Vue.component('my-transition', myTransition)

let VITE_APP_DARK_ENABLE = import.meta.env.VITE_APP_DARK_ENABLE === 'true'
let night = VITE_APP_DARK_ENABLE ? store.state.user.dark : 'day'
store.dispatch("user/setDark",'day');

const opts = {
    breakpoint: {
        thresholds: {
            xs: 340,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
        },
        scrollBarWidth: 24,
    },
    icons: {
        iconfont: 'mdiSvg',	// 设置使用本地的icon资源
    },
    theme: {
        dark: false,
        options: {
            customProperties: true, // 开启自定义属性（可设置字体颜色和背景颜色）
        },
        themes: {
            light: {
                primary: '#999',
                // pro=>7209b7
                // lite=>1c69db
                success: "#1c69db",
                crazy: "#ef1a2d",

                // 自定义主题 => class
                rightBoxBg: '#f5f5f5',
                userLeftBg: '#eee',
                rightBg: '#F5F5F5',
                btnBg: "#000000",
                carFBg: '#999999',
                btnFont: "#FFFFFF",
                rechargeRBG: '#F5F5F5',
                expProgressBg: "#FFFFFF",
                // inputBg: "#000000",
                rechargeBg: '#DDDDDD',
                boxCardBg: "#FFFFFF",
                inputBg:"#FFFFFF",
                black: "#000000",
                white: "#FFFFFF",
                black_grey: "#000000",
                grey_white: '#666666',
                grey_black: '#f0f0f0',
                trans_grey: '#f0f0f0',
                trans1_grey: '#FFFFFF',
                white_grey: '#FFFFFF',
                white_grey02: '#FFFFFF',
                grey_grey01: '#f0f0f0',
                grey_grey02: '#f0f0f0',
                white_grey03: '#FFFFFF',
                grey3_white: '#333333',
                white_night: '#ffffff',
                black_blue: '#000000',
                // background-color:var(--v-cf5_c2a-base); 可以统一这个作为弹出框背景
                cf5_c2a: "#F5F5F5",
                cf5_c28: "#F5F5F5",
                cf5_c21: "#F5F5F5",
                cf5_c51: "#F5F5F5",
                cf6_c21: "#f6f6f6",
                c33_ccc: '#333333',
                cff_c21: "#FFFFFF",
                cff_c74: "#FFFFFF",
                cf0_c2a: "#f0f0f2",
                ccc_c40: "#CCCCCC",
                ccc_cff: "#CCCCCC",
                c66_ccc: '#666666',
                cec_c40: '#ececec',
                cdd_c2a: "#DDDDDD",
                cee_c40: "#EEEEEE",
                cee_c28: '#EEEEEE',
                ccc_cff5: '#CCCCCC',
                c3d_c77: "#1c69db",
                cff_c50: "#FFFFFF",
                cff_c51: "#FFFFFF",
                blue_green1: '#e3f0fd',
                green_c40: '#3d9d43',
                affiliate_lock_icon_bg_color: '#d8d8e2',
                upgrade_content: '#f6f6f6',
                dialog: '#f6f6f6',
                upgrade_odds: '#ffffff',
                upgrade_oddsBtn: '#000000',
                upgrade_input: '#ffffff',
                upgrade_price: '#f5f5f5',
                upgrade_await: '#7d7f83',
                battle_bg: '#f6f6f6',
                battlebox_bg: '#ffffff',
                battleboxwar_bg: '#f0f0f0',
                battlewar_goods: '#f5f5f5',
                battlewar_sum: '#333333',
                battleAvatar_no: '#ffffff',
                battleAvatar_bg: '#f6f5f8',
                detail_bg: '#f5f5f5',
                detail_mode: '#ffffff',
                detail_over_box: '#666666',
                detail_battle_box: '#ffffff',
                detail_box: '#ffffff',
                detail_bg1: '#eeeeee',
                detail_bg2: '#ffffff',
                create_bg1: '#000000',
                create_bg2: '#808080',
                detail_avatar: "#DDDDDD",
                battle_avatar_bg: "#f5f5f5",
            },
            dark: {
                success: '#1c69db',
                primary: '#999',
                crazy: "#ef1a2d",
                // primary: colors.blue.darken1,
                // 自定义主题 => class
                rightBoxBg: '#000002',
                userLeftBg: '#515663',
                rightBg: '#212225',
                rechargeRBG: '#000000',
                carFBg: "#FFFFFF",

                btnBg: "#FFFFFF",
                btnFont: "#000000",
                expProgressBg: "#767887",
                inputBg: "#40444f",
                rechargeBg: "#000000",
                boxCardBg: "#40444F",
                white: "#000000",
                black: "#FFFFFF",
                black_grey: '#CCCCCC',
                grey_white: '#FFFFFF',
                grey_black: "#282A2E",
                trans_grey: '#747988',
                trans1_grey: '#515663',
                white_grey: '#747988',
                grey_grey01: '#3F4453',
                grey_grey02: '#40444F',
                grey3_white: "#FFFFFF",
                white_night: '#2A2D38',
                white_grey02: '#676C7A',
                white_grey03: '#515663',
                black_blue: '#1c69db',
                cf5_c2a: '#2A2D38',
                cf5_c28: '#282A2E',
                cf5_c21: "#212225",
                cf5_c51: "#515663",
                cf6_c21: "#212225",
                cff_c21: '#212225',
                cff_c74: '#747988',
                cf0_c2a: "#2A2D38",
                ccc_c40: "#40444F",
                ccc_cff: "#FFFFFF",
                c66_ccc: '#cccccc',
                cec_c40: '#40444F',
                c33_ccc: '#CCCCCC',
                cee_c40: "#40444F",
                cee_c28: '#282A2E',
                cdd_c2a: '#2A2D38',
                ccc_cff5: '#FF5548',
                c3d_c77: '#77B9E1',
                cff_c50: "#505562",
                cff_c51: '#515663',
                blue_green1: "",
                green_c40: '#40444F',
                affiliate_lock_icon_bg_color: '#192036',
                upgrade_content: '#2a2d37',
                dialog: '#2a2d37',
                upgrade_odds: '#b0aeaf',
                upgrade_oddsBtn: '#3e799c',
                upgrade_input: '#404350',
                upgrade_price: '#212225',
                upgrade_await: '#7b7b7b',
                battle_bg: '#202226',
                battlebox_bg: '#404350',
                battleboxwar_bg: '#272b2e',
                battlewar_goods: '#535562',
                battlewar_sum: '#95999d',
                battleAvatar_no: '#767888',
                battleAvatar_bg: '#40434e',
                detail_bg: '#202226',
                detail_mode: '#545763',
                detail_over_box: '#c2c1c6',
                detail_battle_box: '#767885',
                detail_box: '#50585f',
                detail_bg1: '#515662',
                detail_bg2: '#3f4352',
                create_bg1: '#999999',
                create_bg2: 'rgba(255,255,255,1)',
                detail_avatar: "#000000",
                battle_avatar_bg: "#40444f",
            }
        }
    }
}

export default new Vuetify(opts)

<template>
  <div>

    <v-dialog content-class="v-dialog-right" v-model="value" @click:outside="close" persistent scrollable
      min-height="85%">
      <v-card :elevation="0" rounded="0" color="dialog">
        <v-card-title class="xs:important-px-6 xs:important-py-4 important-font-bold">
          <div class="d-flex justify-space-between align-center w-full">
            <div>
              <span>CART</span>
            </div>
            <icon class-name="pointer v-dialog-close" name="dialog-close" width="25px" height="25px" @click="close">
            </icon>


          </div>
          <div class="mt-2 box_title d-flex justify-space-between align-center w-full" @mousewheel.prevent=""
            @touchmove.prevent="">

            <v-btn depressed class="" height="2.5rem" x-small :ripple="false" color="black" style="width: 46%;" >
              <v-checkbox v-model="selectAll0" class="pa-0 ma-0" color="#FFF" dark :ripple="false" hide-details dense @change="changeSelectAll">
                <template v-slot:label>
                  <div class="select_all_text v-btn">SELECT ALL</div>
                </template>
              </v-checkbox>
            </v-btn>

            <v-btn depressed class="" height="2.5rem" x-small :ripple="false" color="black" style="width: 52%;"
              @click="toCart">
              <div class="text-center">
                <span>View all </span>
                <span style="width: fit-content;background-color:#FFF;color:#000" class="px-1 mx-1 rounded">{{
                  queryPage.total }}</span>
                <span> ITEM<span v-show="queryPage.total">S</span> </span>
              </div>
            </v-btn>
          </div>
        </v-card-title>



        <v-card-text class="cart_order font-weight-bold black--text pa-0  white scrollBar">

          <template v-for="(item, index) in shopList">
            <v-row :class="{ 'check_item': true, check_item_PC: device == 'PC', }" align="center" :key="index"
              @click.stop="setSelectList(item, index)">
              <v-col :cols="1" class="pb-2">
                <v-checkbox :value="item.select" readonly color="black" class="pa-0 ma-0" hide-details dense
                  :ripple="false" v-if="item.cartLock === 0"></v-checkbox>
              </v-col>
              <v-col :cols="10" class="pb-2">
                <div class="cart_page_item_info">
                  <div class="cart_page_item_info_main flex">
                    <div class="carImg flex allcenter" :style="{
                      backgroundColor: bgcolor[dayStatus][item.goodsLevel],
                    }">
                      <v-img :lazy-src="$S3Utils.resizeGoodsPictureLazy(item.goodsPicture)"
                        :src="$S3Utils.resizeGoodsPicture(item.goodsPicture, 0.3)" height="76" :alt="item?.goodsTitle"
                        max-height="3rem"
                        contain class="car_img"></v-img>
                    </div>
                    <div class="cart_page_item_info_box">
                      <div class="cart_page_item_info_title">{{ item.goodsTitle }}</div>
                      <div class="cart_page_item_info_price">
                        <span>{{ getPriceFormat(item) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col :cols="1" class="pb-2 pl-0">
                <v-icon size="16" class="cart_lock pointer" @click.stop="clickCartLock(item, index)">
                  {{ icons[item.cartLock === 1 ? 'mdiLock' : 'mdiLockOpenVariantOutline'] }}
                </v-icon>
              </v-col>

            </v-row>

          </template>
          <div v-intersect="nextPage" style="height: 1px;"></div>

        </v-card-text>

        <v-card-actions class="xs:important-px-6 xs:important-py-4">

          <transition name="fade" mode="out-in">
            <v-progress-linear class="loading-liner" v-show="loading" color="success" buffer-value="0"
              stream></v-progress-linear>
          </transition>


          <transition>
            <div class="box_bottomBtn flex rcenter between">

              <v-btn class=" font-weight-bold" height="2.5rem" x-small :ripple="false" color="success" block
                :loading="isSell" @click="buySelectList">
                <div class="text-center">
                  <span>SELL </span>
                  <span style="width: fit-content;background-color:#FFF" class="success--text px-1 rounded">{{ selectInfo.count }}</span>
                  <span> ITEM{{ selectInfo.count > 1 ? 'S' : '' }} FOR </span>

                  <span style="width: fit-content;background-color:#FFF" class="success--text px-1 rounded">${{
                    (selectInfo.price / 100) | matterPriceDecimal }}</span>
                </div>
              </v-btn>
            </div>
          </transition>
        </v-card-actions>

      </v-card>

    </v-dialog>

  </div>
</template>

<script>

import { sellCarts, cartLock, getCartPage0 } from "@/api/cart";
import { textIncludesKey, priceFormat } from '@/utils/functions'

import { debounce } from "@/utils/functions";
import { mdiLockOpenVariantOutline, mdiLock, mdiChevronRight } from "@mdi/js"
import { BINGO_GOODS_TITLE_KEY } from "@/utils/constants"
import bgcolor from "@/utils/goodsLevelColor2.js";

export default {
  name: "CartHoverBox",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    device: {
      default: "PC",
    },
  },
  watch: {
    value (val, oval) {

      // 打开后关闭
      if (val == true && oval == false) {
        this.getCartList(false);
      } else {
        this.loading = false
      }
    }
  },
  components: {

  },
  data () {
    return {
      bgcolor,
      icons: { mdiLockOpenVariantOutline, mdiLock, mdiChevronRight },
      loading: false,
      buyLimit: 20,
      shopList: [],
      queryPage: {
        pageSize: 20,
        total: 0,
        id: null,
        userId: "",
        sortEnum: "NEWEST_FIRST",
      },
      total: "",
      maxPage: 0,

      oldList: [],
      selectedIds: [],
      bottomHop: 0,
      isSell: false,
      firstLoading: false,
      selectAll0: false,
    };
  },
  created () {
    if(this.$store.state.user.userId) {
      this.getCartList(false);
    }
  },
  mounted () {

  },
  computed: {
    dayStatus () {
      return this.$store.state.user.dark
    },
    selectInfo () {
      let count = { count: 0, price: 0 };
      let selectedList = this.shopList.filter(item => item.select) || []
      if (selectedList.length === 0) {
        return count
      }
      count.price = selectedList.reduce((a, b) => a + b.skuPrice, 0)
      count.count = selectedList.length
      return count;
    },
    // showSelectAll () {
    //   return this.shopList.filter((item, index) => item.cartLock === 0).length || 0
    // },
    // selectAll: {
    //   get () {
    //     console.log("1",this.shopList.filter((item, index) => (index + 1) <= this.buyLimit).some(item => !item.select))
    //     return this.shopList.length != 0 && !this.shopList.filter((item, index) => (index + 1) <= this.buyLimit).some(item => item.select)
    //   },
    //   set (val) {
    //     if (val === false) {
    //       this.shopList.forEach(item => item.select = false)
    //     } else {
    //       this.shopList.filter((item, index) => item.cartLock === 0).filter((item, index) => (index + 1) <= this.buyLimit).forEach(item => item.select = true)
    //     }
    //   },
    // },
  },
  methods: {

    changeSelectAll () {
      if(this.selectAll0 == false) {
        this.shopList.forEach(item => item.select = false)
      } else {
        this.shopList.filter((item, index) => item.cartLock === 0).filter((item, index) => (index + 1) <= this.buyLimit).forEach(item => item.select = true)
      }
    },
    getPriceFormat (item) {

      if (textIncludesKey(BINGO_GOODS_TITLE_KEY.SHIBI, item.goodsTitle)) {
        return `SHIB ${item.skuPrice / 100}`
      }
      let _price = priceFormat(item.skuPrice / 100)
      return `$${_price}`
    },
    nextPage () {
      if(this.firstLoading) return
      debounce(() => this.getCartList(), 500, true)
    },
    clickCartLock (item, index) {
      debounce(() => {
        let lock = item.cartLock === 0 ? 1 : 0
        cartLock({
          lock,
          id: item.id
        }).then((res) => {
          let data = res.data || 0
          if (data > 0) {
            let _item = this.shopList[index]
            _item.cartLock = lock
            if (item.select === true && lock) {
              _item.select = false
            }
            this.shopList[index] = _item
          } else {
            this.$awn.warning("Can't go to unlock");
          }
        })
      }, 500, true)

    },
    toCart () {
      this.$router.push({ path: "/userinfo", query: { nowShow: "cart" } });
      this.close();
    },

    close () {
      this.$emit("input", false);
    },

    onscroll (e) {
      if (this.queryPage.total === this.shopList.length) return;
      if (this.loading === true) return;
      this.top = e.scrollTop === 0;

      const visibleHeight = e.target.scrollHeight - e.target.clientHeight;

      if (e.target.scrollTop >= visibleHeight - 300) {
        this.getCartList()
      }
    },
    /**
     * 最后一个ID
     * @param nextPage
     */
    getCartList (nextPage = true) {
      let { total, ...params } = this.queryPage
      params.pageStart = nextPage ? this.shopList.length : 0

      if (this.loading) return
      this.loading = true;
      getCartPage0(params)
        .then((res) => {
          if (res.data && res.data.list) {
            let _list = res.data.list.map(item => {
              item.select = false
              return item
            });
            if (nextPage) {
              this.shopList = this.shopList.concat(_list)
            } else {
              this.shopList = _list || [];
            }
          }
          this.queryPage.total = res.data.total
          this.loading = false;
          if(this.firstLoading) {
            this.firstLoading = false
          }

        })
        .finally(() => {
          this.loading = false;
        });
    },
    //设置选择数据
    setSelectList (item, index) {
      if (item.cartLock === 1) {
        return
      }
      this.shopList[index].select = !item.select
    },
    hideSellItem (id) {
      let index = this.shopList.findIndex(item => item.id === id)
      this.shopList.splice(index, 1)
    },
    //卖出商品
    async buySelectList () {

      if (this.isSell) {
        return;
      }

      debounce(() => {
        this.isSell = true;
        let ids = this.shopList.filter(item => item.select).map(item => item.id) || []
        if (ids.length === 0) {
          return
        }
        sellCarts({ ids }).then(res => {
          // 移除卖出的
          ids.forEach(id => {
            this.hideSellItem(id)
          })

          this.queryPage.total -= ids.length
          this.$bus.$emit("updataUserinfo");
        }).finally(() => {

          this.selectAll0 = false;
          this.isSell = false;
          this.loading = false;
          // 如果卖完了，再次获取
          this.getCartList(false)
        })
      }, 1000, true)

    },
  },
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.5s;
}

.v-enter {
  transform: translateY(100%);
}

.v-enter-to {
  transform: translateY(0);
}

.v-leave-to {
  transform: translateY(100%);
}

::v-deep .select_all_btn  .v-icon {
  color: #FFF!important;
}
.box_title {
  border-radius: 10px;
  min-height: 3.75rem /* 60/16 */;
  box-sizing: border-box;
  cursor: pointer;

  .title_left {
    width: auto;

    .title_img {
      width: 15.6px;
      height: 15.6px;
      margin-right: 10.92px;
      color: var(--v-black-base);
    }

    .cleft_info {
      span {
        color: var(--v-black-base);
      }

      .tit {
        font-weight: 700;
        font-size: 15px;
        //margin-bottom: 5px;
      }
    }
  }

  .title_right {
    .right_img {
      width: 14px;
      height: 14px;
    }
  }
}

.check_item {
  transition: all .4s;
  margin: 0;

}

.check_item:hover {
  background-color: rgba(0, 105, 219, 0.2);
}

.select_all_text {
  font-size: 12px;
  font-weight: 500;
  color:#FFF;
  font-weight: bold;

}
.loading-liner {
  width: 90%;
  position: absolute;
  bottom: 2.5px;
  z-index: 1;
  @media (min-width: 100px) and (max-width: 600px) {
    position: fixed;
    bottom: 8px;
  }
}


.cart_page_item_info {
  width: 100%;
  overflow: hidden;

  .cart_page_item_info_main {
    width: 100%;

    .carImg {
      width: 76px;
      height: 76px;
      position: relative;
      margin-right: 16px;
      border-radius: 10px;
    }

    .cart_page_item_info_box {
      flex: 1;
      font-weight: 700;
      margin-top: 6px;
      font-size: 12px;

      .cart_page_item_info_title {
        // line-height 18px => 36px
        height: 32px /* 36/16 */;
        overflow: hidden;
      }
      .cart_page_item_info_price {
        margin-top: 6px;
        span {
          padding: 3px 8px;
          border-radius: 10px;
          background-color: var(--v-grey_grey02-base);
        }
      }
    }
  }
}
.box_func {
  margin: 16px 0;
  background-color: var(--v-detail_bg1-base);
  border-radius: 10px;

  .func_all,
  .func_not {
    padding: 10px;
    color: #000;
  }
}
</style>

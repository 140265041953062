<template>
  <div class="loadingbox">
<!--    <div class="box_content" v-if="!finish">-->
    <div class="box_content">
      <v-btn elevation="0" text block
        :class="{ split_loadMore: true, split_loading: loading }"
        @click="getMoreData"
             v-ripple="{ class: `success--text darken-1` }"
      >
        <span v-if="!loading">{{ finish?'NO MORE':'VIEW ALL' }}</span>
        <v-progress-linear
            color="primary lighten-2"
            buffer-value="0"
            stream v-else
        ></v-progress-linear>
<!--        <v-progress-circular-->
<!--          :value="20"-->
<!--          indeterminate-->
<!--          v-else-->
<!--          :size="25"-->
<!--        ></v-progress-circular>-->
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "loadingBox",
  props: {
    //加载状态true，默认状态false
    isLoadMore: {
      default: false,
    },
    loading: {
      default: false,
    },
    finish: {
      default: false,
    },
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    //调用外层加载方法
    getMoreData() {
      if(this.finish){
        return
      }
      this.$emit("getMoreData");
    },
  },
};
</script>

<style lang="scss" scoped>
.loadingbox {
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  .box_content {
    .split_loadMore {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 4px;
      background-color: var(--v-success-base);
      font-size: 15px;
      color: #fff;
      transition: all 1s;
    }
    .split_loadMore:hover {
      background-color: var(--v-success-darken1)!important;
    }
    .split_loading {
      background-color: var(--v-success-darken2)!important;
    }
  }
}
</style>

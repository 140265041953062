<template>
  <div class="channel-list-box scrollBar">
    <div
        :items="channelList"
        :item-height="itemHeight||50"
        :height="height"
        id="channel-list"
        class="channel-list scrollBar"
        :class="[top,device==='PC'?'channel-list-rem':'']"
        @scroll="onscroll">
      <template v-for="(item,i) in channelList" >
        <FriendChannelListItem 
            :key="item.id"
            :itemHeight="itemHeight"
            :icons="icons"
            :modal="modal"
            v-bind="item"
            @click.native="() => $emit('clickChannel', item)"
            @clickDel="() => $emit('clickDel', item)"
            @clickChat="() => $emit('clickChannel', item)"
            @clickAvatar="() => $emit('clickAvatar', item)"
        />
      </template>
      <div v-if="channelList.length>0" v-intersect="nextPage" style="height: 1px;"></div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {FRIEND_STATUS_ENUM_SESSION_MAP} from "@/utils/friendUtil";
import FriendChannelListItem from './FriendChannelListItem.vue';
import {getFriendsList} from "@/api/user";
import {getSessionList} from "@/api/chat";
import { debounce } from "@/utils/functions";
export default {
  components: {FriendChannelListItem},

  props: {
    icons: {
      type: Array,
      default: () => []
    },
    height: String || Number,
    itemHeight: String || Number,
    searchValue: String,
    modal: {
      type: String,
      default: "friend"
    },
    loadingMore: true,
  },
  data: () => ({
    channels: [],
    top: true,
    loading: false,
    hasMore: true,
    total: 0,

  }),
  computed: {
    ...mapGetters({
      singleUnreadCountList: "chat/singleUnreadCountList" || [],
      device: "user/device" || [],
    }),
    channelList: ({singleUnreadCountList, channels}) => {
      return channels.map(channel => {
        let unread = 0
        if (channel.chatMsgSeiId) {
          unread = Number(singleUnreadCountList.find(l => l.chatMsgSeiId === channel.chatMsgSeiId)?.num || 0)
        }
        channel.unread = unread
        return channel
      }).sort((a, b) => b.unread - a.unread)
    },
  },
  created() {
    this.getList()
    if(this.modal === 'session'){
      this.initPushNewMessageBus()
      this.initRefreshSessionBus()
    }
  },
  mounted() {

  },
  beforeDestroy() {
    if(this.modal === 'session'){
      this.destroyInitPushNewMessageBus()
      this.destroyRefreshSessionBus()
    }
  },

  methods: {
    nextPage() {
      debounce(() => this.getList(true), 800, true)
    },
    destroyInitPushNewMessageBus() {
      this.$bus.$off("pushNewMessage")
    },
    initPushNewMessageBus() {
      this.$bus.$on("pushNewMessage", ({chatMsgSeiId,msg}) => {
        let index = this.channels.findIndex(channel=>channel.chatMsgSeiId===chatMsgSeiId)
        if(index != -1){
          let msgData = JSON.parse(msg)
          this.channels[index].newMessage = msgData?.text || msgData?.title
        }
      })
    },
    destroyRefreshSessionBus() {
      this.$bus.$off("refreshSession")
    },
    // 刷新session（添加好友等）chatMsgSeiId=null 刷新全部
    initRefreshSessionBus() {
      this.$bus.$on("refreshSession", (data) => {
        let chatMsgSeiId = data.chatMsgSeiId
        if (chatMsgSeiId) {
          getSessionList({
            chatMsgSeiId
          }).then(res => {
            let data = res.data || []
            if(data.length > 0 && this.channels.length > 0){
              let index = this.channels.findIndex(channel=>channel.chatMsgSeiId===chatMsgSeiId)
              console.log(index)
              console.log(res.data[0])
              if(index != -1){
                this.channels[index] = this.channelItemHandler(res.data[0])
                this.channels.unshift(this.channels.shift())
                console.log(this.channels)
              }
            }
          }).catch(() => {
            this.loading = false
          }).finally(() => {
            this.loading = false
          })
        } else {
          this.getList(false)
        }
      })
    },
    async getList(next = false) {
      //获取好友列表
      if (next && this.hasMore === false) {
        return
      }
      this.loading = true


      let req = null
      let res = null
      let reqParam = {}
      if (this.modal === "friend") {
        req = getFriendsList
        if (next) {
          reqParam = {
            // 最后一个用户的ID
            lastId: this.channels.length > 0 ? this.channels[this.channels.length - 1].id : null
          }
        }
      } else if (this.modal === "session") {
        req = getSessionList
        reqParam = {
          nick: this.searchValue,
          lastTime: this.channels&&this.channels.length>0&&next?this.channels[this.channels.length - 1].updateTime:null,
        }
      }
      req(reqParam).then(res => {

        this.total = res.data.total || 0
        this.$emit('total',this.total)

        let data = res.data?.list || []

        if (this.total === 0) {
          this.hasMore = false
          this.channels = []
        } else if (data.length === 0) {
          this.hasMore = false
        } else if (next===false) {
          this.channels = data.map(this.channelItemHandler)
        } else {

          this.channels.push(...data.map(this.channelItemHandler));
        }
      }).catch(() => {
        this.loading = false
      }).finally(() => {
        this.loading = false
      })

    },
    channelItemHandler(item){
      if(item.status && item.status !== 0 ){
        let status = FRIEND_STATUS_ENUM_SESSION_MAP[item.status]
        if(status){
          item.statusIcon = status.icon
          item.statusDesc = status.description
          item.statusColor = status.color
        }
      }
      return item
    },
    onscroll(e) {
      if (this.loadingMore === false) return;
      if (this.loading === true) return;
      this.top = e.scrollTop === 0;

      if (this.hasMore === false) return;
      const visibleHeight = e.target.scrollHeight - e.target.clientHeight;

      if (e.target.scrollTop >= visibleHeight - 1) {
        this.getList(true)
      }
    },
  },
}
</script>


<style lang="scss" scoped>
.channel-list-box{
  //overflow-y: scroll;
  //height: 350px;
}
.channel-list {
  width: 100%;
  height: 100%;
}
.channel-list-rem {
  //height: 350px;
}
.channel-list-rem {
  height: 350px;
}

.channel-list.top {
  border-top: 1px solid transparent;
}

</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '@/views/index/index.vue'
Vue.use(VueRouter)

//避免盒子访问同一页面带同一参数报路由重复错误，重写push和replace
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// 指定新的push方法
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    // 如果指定了成功或者失败的回调
    if (onResolve || onReject) {
        // 直接调用原本的push方法
        return originalPush.call(this, location, onResolve, onReject)
    }
    // 没有指定成功或者失败的回调，要用catch处理
    return originalPush.call(this, location).catch((err) => {
        // 如果是重复导航产生的错误，不再向外传递错误
        if (VueRouter.isNavigationFailure(err)) {
            // 产生的是成功的promise，成功的promise的value是err
            // 产生失败的promise：抛出错误或者return一个失败的promise
            return err
        }
        // 如果不是重复导航的错误，将错误向下传递
        return Promise.reject(err)
    })
}
//原理同上
VueRouter.prototype.replace = function (location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalReplace.call(this, location, onResolve, onReject)
    }
    return originalReplace.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }
        return Promise.reject(err)
    })
}

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
        title: 'Home',
        metaTags: [
            // {
            //     name: 'description',
            //     content: 'ALL NEW ALL FREE UNBOXING SITE. At OnliBox Lite we do not charge you a single penny to open boxes.We want you to have fun while not worrying about your wallet. You can claim a 100,000$ credit every hour'
            // },
            // {
            //     property: 'og:description',
            //     content: 'The home page of OnliBox Lite.'
            // }
        ]
    }
},
    {
        path: '/clash',
        name: 'Clash',
        component: () => import('@/views/clash/clash.vue'),
        meta: {
            title: 'CLASH',
            metaTags: [
                {
                    name: 'description',
                    content: 'Choose your favorite boxes and try your luck against other players! It is a game of wits and luck, and the ultimate winner takes all!'
                },
                {
                    property: 'og:description',
                    content: 'Choose your favorite boxes and try your luck against other players! It is a game of wits and luck, and the ultimate winner takes all!'
                }
            ],
            showLiveDrops: false,
        }
    },

    {
        path: '/create',
        name: 'create',
        component: () => import('@/views/clash/create.vue'),
        meta: {
            title: 'CLASH',
            metaTags: [
                {
                    name: 'description',
                    content: 'Create a new Clash and try your luck against other players'
                },
                {
                    property: 'og:description',
                    content: 'Create a new Clash and try your luck against other players'
                }
            ],
            showFooter: false,
        }
    },
    {
        path: '/clash/detail',
        name: 'detail',
        component: () => import('@/views/clash/detail_plus.vue'),
        meta: {
            title: 'CLASH',
            metaTags: [
                {
                    name: 'description',
                    content: 'Choose your favorite boxes and try your luck against other players! It is a game of wits and luck, and the ultimate winner takes all!'
                },
                {
                    property: 'og:description',
                    content: 'Choose your favorite boxes and try your luck against other players! It is a game of wits and luck, and the ultimate winner takes all!'
                }
            ],
            showLiveDrops: true,
            showLiveDropsH5: false,
            showFooter: false,
        }
    },
    {
        path: '/clash/detail_plus',
        name: 'detail_plus',
        component: () => import('@/views/clash/detail.vue'),
        meta: {
            title: 'CLASH PLUS',
            metaTags: [
                {
                    name: 'description',
                    content: 'Choose your favorite boxes and try your luck against other players! It is a game of wits and luck, and the ultimate winner takes all!'
                },
                {
                    property: 'og:description',
                    content: 'Choose your favorite boxes and try your luck against other players! It is a game of wits and luck, and the ultimate winner takes all!'
                }
            ],
            showLiveDrops: false,
        }
    },

// {
// 	path: '/clash/detail-test',

// 	name: 'detail-test',
// 	component: () => import('@/views/clash/detail-test.vue'),
// },
//     {
//         path: '/info/provably-fair',
//         name: 'provably fair',
//         component: () => import('@/views/info/provably-fair.vue'),
//         meta: {
//             title: 'FAIRNESS',
//             metaTags: [
//                 {
//                     name: 'description',
//                     content: 'OnliBox Lite is provably fair. You can verify the fairness of the game by using the our open algorithm.'
//                 },
//                 {
//                     property: 'og:description',
//                     content: 'OnliBox Lite is provably fair. You can verify the fairness of the game by using the our open algorithm.'
//                 }
//             ]
//         }
//     },
    {
        path: '/UserInfo',
        name: 'userinfo',
        component: () => import('@/views/user/UserInfo.vue'),
        meta: {
            title: 'Profile',
            metaTags: [
                {
                    name: 'description',
                    content: 'OnliBox Lite User Profile'
                },
                {
                    property: 'og:description',
                    content: 'OnliBox Lite User Profile'
                }
            ],
            // showLiveDrops=false
            // showLiveDropsH5=false
            // showLiveDropsPC=false
            showLiveDrops: false,
            showFooter: false,
        }
    },
    {
        path: '/unboxing',
        name: 'Unboxing',
        component: () => import('@/views/splitBox/splitBox.vue'),
        meta: {
            title: 'Open Mystery Boxes | Provably Fair and Authentic',
            metaTags: [
                {
                    name: 'description',
                    content: 'A unique and exciting unboxing experience that allows you to discover and earn a wide range of fashionable items. You never know what epic products you might get when you open a box on OnliBox. It could be a high-end designer purse, the newest AJs, or a trendy piece of jewelry. The possibilities are endless! '
                },
                {
                    property: 'og:description',
                    content: 'A unique and exciting unboxing experience that allows you to discover and earn a wide range of fashionable items. You never know what epic products you might get when you open a box on OnliBox. It could be a high-end designer purse, the newest AJs, or a trendy piece of jewelry. The possibilities are endless! '
                }
            ],
            showLiveDrops: false,
        }

    },
    {
        path: '/boxinfo/:id',
        name: 'boxinfo',
        component: () => import('@/views/splitInfo/splitInfo.vue'),
        meta: {
            title: 'Box Detail',
            // metaTags: [
            //     {
            //         name: 'Box Detail',
            //         content: 'A unique and exciting unboxing experience that allows you to discover and earn a wide range of fashionable items. You never know what epic products you might get when you open a box on OnliBox. It could be a high-end designer purse, the newest AJs, or a trendy piece of jewelry. The possibilities are endless! '
            //     },
            //     {
            //         property: 'og:description',
            //         content: 'A unique and exciting unboxing experience that allows you to discover and earn a wide range of fashionable items. You never know what epic products you might get when you open a box on OnliBox. It could be a high-end designer purse, the newest AJs, or a trendy piece of jewelry. The possibilities are endless! '
            //     }
            // ],
            showLiveDrops: true,
            showLiveDropsH5: false,
        }
    },
    {
        path: '/box/:code',
        name: 'boxinfo',
        component: () => import('@/views/splitInfo/splitInfo.vue'),
        meta: {
            title: 'Box Detail',
            // metaTags: [
            //     {
            //         name: 'Box Detail',
            //         content: 'A unique and exciting unboxing experience that allows you to discover and earn a wide range of fashionable items. You never know what epic products you might get when you open a box on OnliBox. It could be a high-end designer purse, the newest AJs, or a trendy piece of jewelry. The possibilities are endless! '
            //     },
            //     {
            //         property: 'og:description',
            //         content: 'A unique and exciting unboxing experience that allows you to discover and earn a wide range of fashionable items. You never know what epic products you might get when you open a box on OnliBox. It could be a high-end designer purse, the newest AJs, or a trendy piece of jewelry. The possibilities are endless! '
            //     }
            // ],
            showLiveDrops: true,
            showLiveDropsH5: false,
        }
    },
    {
        path: '/affiliates',
        name: 'affiliates',
        component: () => import('@/views/affiliates/index.vue'),
        meta: {
            title: 'Affiliate',
            metaTags: [
                {
                    name: 'description',
                    content: 'Invite your friends to play, and earn bonuses when they play. They get a bonus too!'
                },
                {
                    property: 'og:description',
                    content: 'Invite your friends to play, and earn bonuses when they play. They get a bonus too!'
                }
            ],
            showFooter: false,
        }
    },
    {
        path: '/affiliates/count',
        name: 'Affiliate',
        component: () => import("@/views/affiliates/affiliateCount.vue"),
        meta: {
            title: 'Affiliate',
            metaTags: [
                {
                    name: 'description',
                    content: 'Invite your friends to play, and earn bonuses when they play. They get a bonus too!.'
                },
                {
                    property: 'og:description',
                    content: 'Invite your friends to play, and earn bonuses when they play. They get a bonus too!'
                }
            ],
            showFooter: false,
        }
    },
    {
        path: '/affiliates/share/:code?',
        name: 'share',
        component: () => import("@/views/affiliates/shareAffiliate.vue"),
        meta: {
            keepAlive: true,
            title: 'Join OnliBox Lite Today!',
            metaTags: [
                {
                    name: 'description',
                    content: 'Join your friend on OnliBox Lite today! Both of you will receive a bonus'
                },
                {
                    property: 'og:description',
                    content: 'Join your friend on OnliBox Lite today! Both of you will receive a bonus'
                }
            ],
            showFooter: false,
        }
    },
    {
        path: '/r/:code?',
        name: 'shareSelect',
        component: () => import("@/views/affiliates/ReferFreeBoxNovember.vue"),
        meta: {
            title: '🔥 Open 5 free mystery boxes',
            metaTags: [
                {
                    name: 'description',
                    content: 'Test your luck and draw the highest value $1599 iPhone, sneakers and game console for free! No registration required, participate now and win great prizes in five lucky boxes!'
                },
                {
                    property: 'og:description',
                    content: 'Test your luck and draw the highest value $1599 iPhone, sneakers and game console for free! No registration required, participate now and win great prizes in five lucky boxes!'
                }
            ],
            links: [
                { rel: 'canonical', href: "https://www.onlibox.com/r/onlibox", id: 'canonical' }
            ],
            showLiveDrops: false,
        }
    },
    {
        path: '/fans/:code?',
        name: 'FansFreeBox',
        component: () => import("@/views/affiliates/FansFreeBox.vue"),
        meta: {
            title: 'Join OnliBox Lite Today!',
            metaTags: [
                {
                    name: 'description',
                    content: 'Join your friend on OnliBox Lite today! Both of you will receive a bonus'
                },
                {
                    property: 'og:description',
                    content: 'Join your friend on OnliBox Lite today! Both of you will receive a bonus'
                }
            ],
            showLiveDrops: false,
        }
    },
    {
        path: '/refer/:code?',
        name: 'share',
        component: () => import("@/views/affiliates/ReferFreeBox.vue"),
        meta: {
            title: 'Join OnliBox Lite Today!',
            metaTags: [
                {
                    name: 'description',
                    content: 'Test your luck and draw the highest value $1599 iPhone, sneakers and game console for free! No registration required, participate now and win great prizes in five lucky boxes!'
                },
                {
                    property: 'og:description',
                    content: 'Test your luck and draw the highest value $1599 iPhone, sneakers and game console for free! No registration required, participate now and win great prizes in five lucky boxes!'
                }
            ],
            links: [
                { rel: 'canonical', href: "https://www.onlibox.com/r/onlibox", id: 'canonical' }
            ],
            showLiveDrops: false,
        }
    },
    {
        path: '/choice',
        name: 'choice',
        component: () => import("@/views/affiliates/shareSelect.vue"),
        meta: {
            title: 'Join OnliBox Lite Today!',
            metaTags: [
                {
                    name: 'description',
                    content: 'Join your friend on OnliBox Lite today! Both of you will receive a bonus'
                },
                {
                    property: 'og:description',
                    content: 'Join your friend on OnliBox Lite today! Both of you will receive a bonus'
                }
            ],
            showLiveDrops: false,
        }
    },
    {
        path: '/freedrop',
        name: 'freedrop',
        component: () => import('@/views/freeDrop/freeDrop.vue'),
        meta: {
            title: 'Rewards',
            metaTags: [
                {
                    name: 'description',
                    content: 'Claim your daily rewards and open free boxes'
                },
                {
                    property: 'og:description',
                    content: 'Claim your daily rewards and open free boxes'
                }
            ]
        }
    },
    {
        path: '/howitworks',
        name: 'How OnliBox Works',
        component: () => import('@/views/howitworks/index.vue'),
        meta: {
            title: 'How It Works',
            metaTags: [
                {
                    name: 'description',
                    content: 'Learn more about OnliBox Lite and how it works'
                },
                {
                    property: 'og:description',
                    content: 'Learn more about OnliBox Lite and how it works'
                }
            ]
        }
    },
    {
        path: '/resetpassword/:code?',
        name: 'resetPassword',
        component: () => import('@/views/resetPassword/resetPassword.vue'),
        meta: {
            title: 'Password Reset',
            metaTags: [
                {
                    name: 'description',
                    content: 'Reset your password'
                },
                {
                    property: 'og:description',
                    content: 'Reset your password'
                }
            ]
        }
    },
    {
        path: '/activation/:code?',
        name: 'activation',
        component: () => import('@/views/activation/activation.vue'),
        meta: {
            title: 'Complete Register',
            metaTags: [
                {
                    name: 'description',
                    content: 'Verify your email address'
                },
                {
                    property: 'og:description',
                    content: 'Verify your email address'
                }
            ]
        }
    },
    // 发货页面
    {
        path: '/myDelievers',
        name: 'My Deliveries',
        component: () => import('@/views/myDelievers/index.vue'),
        meta: {
            title: 'Deliveries',
            metaTags: [
                {
                    name: 'description',
                    content: 'Check the status of everything you have ordered on OnliBox Lite'
                },
                {
                    property: 'og:description',
                    content: 'Check the status of everything you have ordered on OnliBox Lite'
                }
            ],
            showLiveDrops: false,
        }
    },
    {
        path: '/leaderboard',
        name: 'leaderboard',
        component: () => import('@/views/leaderboard/leaderboard.vue'),
        meta: {
            title: 'Leaderboard',
            metaTags: [
                {
                    name: 'description',
                    content: 'Discover your ranking on OnliBox Lite and unlock a world of rewards, including gift cards and more!'
                },
                {
                    property: 'og:description',
                    content: 'Discover your ranking on OnliBox Lite and unlock a world of rewards, including gift cards and more!'
                }
            ],
            showLiveDrops: false,
        }
    },
    {
        path: '/u/:friendId',
        name: 'public-user',
        component: () => import('@/views/user/publicUser.vue'),
        meta: {
            title: 'Friends',
            metaTags: [
                {
                    name: 'description',
                    content: 'Meet new friends on OnliBox Lite, stay connected with them'
                },
                {
                    property: 'og:description',
                    content: 'Meet new friends on OnliBox Lite, stay connected with them'
                }
            ]
        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/things/everything.vue'),
        redirect: '/everything',
        meta: {
            title: 'About OnliBox Lite',
            metaTags: [
                {
                    name: 'description',
                    content: 'Learn more about OnliBox Lite and how it works'
                },
                {
                    property: 'og:description',
                    content: 'Learn more about OnliBox Lite and how it works'
                }
            ]
        }
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import('@/views/about/termsofservice.vue'),
        meta: {
            title: 'About OnliBox Lite',
            metaTags: [
                {
                    name: 'description',
                    content: 'OnliBox Lite Terms of Service'
                },
                {
                    property: 'og:description',
                    content: 'OnliBox Lite Terms of Service'
                }
            ]
        }
    },
    {
        path: '/cookiepolicy',
        name: 'cookiepolicy',
        component: () => import('@/views/about/cookie.vue'),
        meta: {
            title: 'OnliBox Lite Cookie Policy',
            metaTags: [
                {
                    name: 'description',
                    content: 'OnliBox Lite Cookie Policy'
                },
                {
                    property: 'og:description',
                    content: 'OnliBox Lite Cookie Policy'
                }
            ]
        }
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/about/privacy.vue'),
        meta: {
            title: 'OnliBox Lite Privacy Policy',
            metaTags: [
                // {
                //     name: 'description',
                //     content: 'OnliBox Lite Privacy Policy'
                // },
                // {
                //     property: 'og:description',
                //     content: 'OnliBox Lite Privacy Policy'
                // }
            ]
        }
    },
    {
        path: '/algorithm',
        name: 'algorithm',
        component: () => import('@/views/algorithm/algorithm.vue'),
        meta: {
            title: 'OB Algorithm',
            metaTags: [
                {
                    name: 'description',
                    content: 'Fairness and transparency are the core values of OnliBox Lite. Learn more about our algorithm and how to verift your box open results'
                },
                {
                    property: 'og:description',
                    content: 'Fairness and transparency are the core values of OnliBox Lite. Learn more about our algorithm and how to verift your box open results'
                }
            ]
        }
    },
    {
        path: '/everything',
        name: 'everything',
        component: () => import('@/views/things/everything.vue'),
        meta: {
            title: 'Everything Everywhere',
            metaTags: [
                {
                    name: 'description',
                    content: 'Unleash the potential of OnliBox Lite - delve into our algorithm, verify box open results, and unlock greater rewards.'
                },
                {
                    property: 'og:description',
                    content: 'Unleash the potential of OnliBox Lite - delve into our algorithm, verify box open results, and unlock greater rewards.'
                }
            ],
            showLiveDrops: false,
        }
    },
    {
        path: '/demo',
        name: 'demo',
        component: () => import('@/views/demo/demo.vue'),
    },

    {
        path: '/my-products',
        name: 'my-products',
        redirect: '/archive',
        component: () => import('@/views/user/statistics/StatisticsUserSpu.vue'),
        meta: {
            showLiveDrops: false,
        }
    },
    {
        path: '/keepsakes',
        name: 'keepsakes',
        redirect: '/archive',
        component: () => import('@/views/user/statistics/StatisticsUserSpu.vue'),
        meta: {
        keepAlive: true,
            showLiveDrops: false,
        }
    },
    {
        path: '/archive',
        name: 'archive',
        component: () => import('@/views/user/statistics/StatisticsUserSpu.vue'),
        meta: {
        keepAlive: true,
            showLiveDrops: false,
        }
    },
    {
        path: '/recharge',
        name: 'recharge',
        component: () => import('@/views/recharge/Recharge.vue'),
    },
    // 404 页面跳转首页
    {
        path: '*',
        name: '404',
        redirect: '/',
        component: () => import('@/views/index/index.vue'),
    }
]

const router = new VueRouter({
    base: "/",
    mode: 'history', // 去掉url中的#
    routes,
    scrollBehavior(to, from, savedPosition) {

        return {y: 0} //路由跳转不记录y轴位置信息
    }
})
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

    // If a route with a title was found, set the document (page) title to that value.
    if (to.meta.title && to.meta.title != '') {
        document.title = 'OnliBox Lite - '+ to.meta.title;
    } else {
        document.title = 'OnliBox Lite - Open Mystery Boxes | Provably Fair and Authentic';
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
    let metaTags = to?.meta?.metaTags;
    if(!metaTags || metaTags.length == 0) {
        let description = "Experience the magic of well-designed and charming mystery boxes in onlibox. Get ready to embark on an exciting journey into the unknown."
        metaTags = [
            {
                name: 'description',
                content: description
            },
            {
                property: 'og:description',
                content: description
            }
        ]
    }
    metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    }).forEach(tag => document.head.appendChild(tag));

    if(to?.meta?.links) {
        to.meta.links.forEach(link => {
            const linkEl = document.createElement('link');
            linkEl.setAttribute('rel','stylesheet');
            linkEl.setAttribute('href', link.href);
            linkEl.setAttribute('data-vue-router-controlled', '');
            document.head.appendChild(linkEl);
        });
    }

    next();
});
export default router

import { getLiveDrop,getLiveDropRandLucky } from "@/api/game";
import {getConfig} from "@/api/user";

let liveDropLoading = false

const state = {
  rootValue: 16,
  navigator:[
    {
        "name": "UNBOXING",
        "url": "/unboxing",
        "index": 1
    },
    {
        "name": "CLASHES",
        "url": "/clash",
        "index": 2
    },
    {
        "name": "AFFILIATES",
        "url": "/affiliates/count",
        "index": 3
    },
    {
        "name": "LEADERBOARD",
        "url": "/leaderboard",
        "index": 6
    },
    {
        "name": "HOW ONLIBOX WORKS",
        "url": "/about",
        "index": 8
    }
],
  navigatorMobile:[],
  bingoCount: {
    bingo: {},
    count: null,
  },
  liveDropsData:{
    list: [],
    leaderboardList: [],
    queryPage:{
      hashId:null,
      bHashId:null,
    },
  },

};

const mutations = {
  SET_BINGO_COUNT(state, value) {
    state.bingoCount = value;
  },
  SET_ROOT_VALUE(state, value) {
    state.rootValue = value;
  },
};

const getters = {
  bingoCount: state => state.bingoCount,
  liveDrops: state => state.liveDropsData.list,
  liveDropsLeaderboard: state => state.liveDropsData.leaderboardList,
  rootValue: state => state.rootValue,
  navigator: state => state.navigator,
  navigatorMobile: state => state.navigatorMobile,
}
const actions = {
  getNavigator({state,root},device){
    // let code = device == 'PC' ? "NAVIGATOR":"NAVIGATOR_MOBILE"
    // getConfig(code)
    //     .then(({ data }) => {
    //       if(device == 'PC') {
    //         state.navigator = data.sort((l1,l2)=>l1.index-l2.index) || []
    //       } else {
    //         state.navigatorMobile = data || []
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
  },
  setBingoCount({commit},value){
    commit('SET_BINGO_COUNT',value)
  },
  setRootValue({commit},value){
    commit('SET_ROOT_VALUE',value)
  },
  // 添加group
  getLiveDropsRank({dispatch , state}) {
    // leaderboardList
    getLiveDropRandLucky().then(res=>{
      state.liveDropsData.leaderboardList = res.data || []
    })
  },
  getLiveDrops({dispatch , state},start=false) {
    if(start){
      state.liveDropsData.queryPage = {
        hashId:null,
        bHashId:null,
      }
    }
    if(liveDropLoading){
      return
    }
    liveDropLoading = true

    getLiveDrop({
      ...state.liveDropsData.queryPage,
      t: Date.now(),
    }).then(res=>{
      let data = res.data
      if(data && data.length > 0) {
        //设置颜色标识

        // hash id
        state.liveDropsData.queryPage.hashId = data[0].hashId
        // battle hash id
        let lastBattle = data.find(l=>l.battleId&&l.battleId!==0)
        if(lastBattle){
          state.liveDropsData.queryPage.bHashId = lastBattle.hashId
        }

        //过滤已存在数据
        let list = data.filter(item => state.liveDropsData.list.findIndex(v => v.hashId === item.hashId)===-1)
        if(list.length===0) {
          return
        }
        let _list = state.liveDropsData.list
        _list.unshift(...list)
        // 只显示20个
        if(_list.length>20){
          _list.splice(20,state.liveDropsData.list.length - 20)
        }
        state.liveDropsData.list = _list

      }
    }).finally(()=>{
      liveDropLoading = false
    })
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};


import Vue from 'vue';

// 创建一个响应式的窗口宽度
const state = Vue.observable({ width: window.innerWidth });

// 监听窗口大小变化
window.addEventListener('resize', () => {
    state.width = window.innerWidth;
});

// 定义断点
const breakpoints = ['sm', 'md', 'lg', 'xl'];

// 创建一个全局函数来获取当前的断点
Vue.prototype.$breakpoint = function () {
    let breakpoint;
    if (state.width < 768) {
        breakpoint = 'sm';
    } else if (state.width < 1024) {
        breakpoint = 'md';
    } else if (state.width < 1280) {
        breakpoint = 'lg';
    } else {
        breakpoint = 'xl';
    }

    let currentBreakpointIndex = breakpoints.indexOf(breakpoint);
    // console.log({current: breakpoint})
    return {
        current: breakpoint,
        ge: (minBreakpoint) => currentBreakpointIndex >= breakpoints.indexOf(minBreakpoint),
        le: (maxBreakpoint) => currentBreakpointIndex <= breakpoints.indexOf(maxBreakpoint),
        eq: (eqBreakpoint) => currentBreakpointIndex === breakpoints.indexOf(eqBreakpoint),
    };
};

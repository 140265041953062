<template>
  <div class="index_bannerList relative">
    <swiper class="index_banner hover-scale" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in bannerList" :key="index">
        
        <div v-if="item?.bannerImageUrl" flex items-center justify-center>
          <v-img
          :src="$S3Utils.resizeHomeBanner(item.bannerImageUrl)" 
          :lazy-src="$S3Utils.resize(item.bannerImageUrl, 160, 90)" 
          @click="clickBanner(item)"
          class="pointer banner-image w-full object-cover h-[180px] md:h-65 lg:h-105 xl:h-125"
          v-track="{category: 'banner', value: 'banner_href', label: 'preview'}"
          :alt="`onlibox ${item.bannerName}`">
          </v-img>
        </div>
          <v-skeleton-loader v-else boilerplate class="h-[180px] md:h-[480px]" width="100%" type="image"></v-skeleton-loader>

      </swiper-slide>

      <div class="swiper-button-prev elevation-1 hover-scale" slot="button-prev" v-track="{category: 'banner', value: 'banner_prev', label: 'preview'}">
        <v-icon color="white" class="" size="16">{{ icons.mdiChevronLeft }}</v-icon>
      </div>
      <div class="swiper-button-next elevation-1 hover-scale" slot="button-next" v-track="{category: 'banner', value: 'banner_next', label: 'preview'}">
        <v-icon color="white" class="" size="16">{{ icons.mdiChevronRight }}</v-icon>
      </div>

    </swiper>

  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css' // 注意这里的引入


import { mdiChevronLeft, mdiChevronRight } from "@mdi/js"

export default {
  name: "IndexBannerArea",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    configList: {
      type: Object,
      default: () => {
      }
    },
    device: {
      type: String,
      default: ''
    },
  },
  computed: {
  },
  data: () => ({
    icons: {
      mdiChevronLeft, mdiChevronRight
    },
    bannerList: [{}],
    swiper: {},
    swiperOptions: {
      // 衔接滑动
      loop: true,
      // 自动滑动
      autoplay: {
        delay: 8000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      spaceBetween: 32,
      slideToClickedSlide: true,
      // 使用前进后退按钮来控制Swiper切换。
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      // pagination: {
      //   el: '.swiper-pagination', //与slot="pagination"处 class 一致
      //   clickable: true,
      //   type: 'fraction',
      // },
    },
  }),
  created () {
    this.bannerList = this.$store.state.config?.configList?.bannerList || [{}]
    console.log(this.bannerList)
  },
  mounted () {

  },
  methods: {

    clickBanner (item) {
      console.log(item)
      if (item.jumpUrl.startsWith('/')) {
        this.$router.push(item.jumpUrl)
      } else if (item.jumpUrl.startsWith('http')) {
        window.open(item.jumpUrl)
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.index_bannerList {
  border-radius: 10px;
  padding: 1rem;
  overflow: hidden;
}
.index_banner {
  border-radius: 10px;
  &:hover {
    transform: scale(1.01);
  }
}
.banner-image {
  border-radius: 10px;

}
.swiper-button-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 12;
}
.swiper-button-prev {
  left: 32px;
}
.swiper-button-next {
  right: 32px;
}
/* 自定义导航按钮样式 */
.swiper-button-prev,
.swiper-button-next {
  background-color: #11111133;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // 被index goods 的给禁用了 造成无法点击
  pointer-events: auto !important;
  cursor: pointer!important;
  opacity: 1!important;

  /* ... */
  &::after {
    display: none;
  }
}

/* 自定义分页器样式 */
.swiper-pagination-bullet {
  /* ... */
}

.swiper-pagination-bullet-active {
  /* ... */
}
@media (max-width:1200px) {
  .index_bannerList {
    margin-top: 0.25rem;
    padding: 0 0.25rem;
  }

  .swiper-button-next,.swiper-button-prev {
    display: none;
  }

}
</style>

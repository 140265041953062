<template>
  <div>
    <v-navigation-drawer v-model="drawer" absolute right temporary @input="input">
      <div class="more-menu-title-box">
        <div class="my-8" v-for="(funcListItem, i) in funcList" :key="i">
          <div v-ripple="{class:'rightBg--text'}" v-for="(item, index) in funcListItem" :key="index" class="c66_ccc--text more-menu-title"
            v-if="checkAuth(item)" link @click="openFunc(item)">
            <icon v-if="item?.svg" :name="item.svg" :className="item.svg" class="mx-2 more-menu-title-icon"></icon>
            <v-icon v-else color="c66_ccc" class="mx-2 more-menu-title-icon">{{ icons[item.icon] }}</v-icon>
            <div class="font-weight-bold">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ICON_NAME } from '@/utils/constants.js'
import {
  mdiAccountSupervisor, mdiHeadphonesSettings, mdiDomain,
  mdiEmail, mdiLogoutVariant,
  mdiPowerSocketUs, mdiProfessionalHexagon,
  mdiClipboardTextOutline,
  mdiTruckCheckOutline,
} from '@mdi/js';
export default {
  name: "modelMenu",
  watch: {
    group () {
      this.drawer = false
    },
    value (val) {
      if (val == true) {
        this.drawer = true
      }
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },

    device: {
      default: "PC",
    },
  },
  components: {
  },
  computed: {
    ...mapGetters({
      dark: "user/dark",
      isLogin: "user/isLogin",
      navigatorMobile: "appData/navigatorMobile",
    }),
    getDarkThemeIcon: ({ dark }) => ICON_NAME.darkThemeIcon[dark],
    checkAuth: ({ isLogin }) => {
      return (item) => {
        if (item?.show == false) {
          return false
        }
        if (!item?.auth) {
          return true
        }
        if (item?.auth == true && !isLogin) {
          return false
        } else {
          return true
        }
      }
    },
  },
  data () {
    return {
      icons: {
        mdiAccountSupervisor, mdiHeadphonesSettings, mdiDomain, mdiEmail,
        mdiLogoutVariant, mdiPowerSocketUs, mdiProfessionalHexagon,
        mdiClipboardTextOutline,
        mdiTruckCheckOutline,
      },
      funcList: [
        [
          { svg: 'model-box', icon: "", title: "UNBOXING", auth: false, code: 'unboxing', url: '/unboxing' },
          { svg: 'model-batel', icon: "mdiDomain", title: "CLASHES", auth: false, code: 'clash', url: '/clash' },
          // { svg: 'model-treasure-chest', icon: "mdiDomain", title: "ARCHIVE", auth: false, code: 'clash', url: '/archive' },
          { svg: '', icon: "mdiDomain", title: "AFFILIATES", auth: false, code: 'affiliates', url: '/affiliates/count' },
          { svg: 'model-leaderboard', icon: "mdiDomain", title: "LEADERBOARD", auth: false, code: 'leaderboard', url: '/leaderboard' },
          // {svg:'',icon:"mdiPowerSocketUs", title:"HOW ONLIBOX WORKS",auth:false,code:'about',url:'/everything'},
          // { svg: '', icon: "mdiProfessionalHexagon", title: "MORE", auth: false, code: 'choice', url: '/choice', show: window.location.host.includes('lite') || import.meta.env.DEV },
        ],
        [
          { svg: '', icon: "mdiClipboardTextOutline", title: "PLAZA", auth: false, code: 'friends', url: '/userinfo?nowShow=plaza' },
          { svg: 'userInfo-01', icon: "", title: "MY FRIEND", auth: false, code: 'friends', url: '/userinfo?nowShow=MyFriend' },
          { svg: 'userInfo-06', icon: "", title: "CART", auth: false, code: 'friends', url: '/userinfo?nowShow=cart' },
          { svg: 'model-peo', icon: "", title: "PROFILE", auth: false, code: 'friends', url: '/userinfo?nowShow=account' },
          { svg: 'userInfo-07', icon: "", title: "STATS", auth: false, code: 'friends', url: '/userinfo?nowShow=stats' },
          { svg: 'userInfo-05', icon: "", title: "FAIRNESS", auth: false, code: 'friends', url: '/userinfo?nowShow=fairness' },
          { svg: 'userInfo-08', icon: "", title: "GAME HISTORY", auth: false, code: 'friends', url: '/userinfo?nowShow=history' },
          { svg: 'userInfo-09', icon: "", title: "TRANSACTIONS", auth: false, code: 'friends', url: '/userinfo?nowShow=transitions' },
          { svg: '', icon: "mdiTruckCheckOutline", title: "DELIVERY", auth: false, code: 'friends', url: '/userinfo?nowShow=supportTickets' },
          { icon: "dark", title: "DARK THEME", auth: false, code: 'theme', show: import.meta.env.VITE_APP_DARK_ENABLE == 'true' },
          { icon: "mdiLogoutVariant", title: "LOGOUT", auth: true, code: 'logout' }
        ],
      ],
      drawer: false,
      group: null,
    };
  },
  created () {
  },
  mounted () {
  },
  methods: {
    input (e) {
      this.$emit("input", false)
    },
    openFunc (item) {
      let { code, auth } = item
      if (!this.isLogin && auth) {
        this.$awn.warning("Please login first");
        return;
      }
      if (item?.url) {
        this.$router.push(item?.url);
        return
      }
      switch (code) {
        case "logout":
          this.$emit("input", false)
          this.$bus.$emit("outPut");

          break;
        case "theme":
          this.$emit("changeDark");
          break;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .v-navigation-drawer {
  height: 100vh!important;
  width: 226px!important;
  .v-navigation-drawer__content {
    height: 100vh;
    .v-list {
      .v-list-item {
      }
    }
  }
}

.more-menu-title-box {
  height: 100%;
  padding: calc(env(safe-area-inset-top) + 1rem) 1rem 1rem 1rem;
  background-color: var(--v-white_night-base);
  .more-menu-title {

    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    height: 40px;

    display: flex;
    justify-items: space-between;
    align-items: center;
    .more-menu-title-icon {
      width: 20px;
      height: 20px;
    }

  }
}
</style>

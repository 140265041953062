<template>
    <div class="confetti-canvas-wrapper">
        <!-- <canvas ref="canvas" class="canvas-sky"></canvas> -->
        <canvas class="absolute" id="confetti-canvas" style="z-index: 0"></canvas>
    </div>
</template>

<script>
// 这是一个下雪的组件 放在app.vue
import ConfettiGenerator from "confetti-js";
export default {
    name: 'ChristmasSky',
    props: {
        // count: {
        //     type: Number,
        //     default: 100
        // },
        // speed: {
        //     type: Number,
        //     default: 0.5
        // }
    },
    data () {
        return {
            confetti: null,
        }
    },
    mounted () {
        console.log('ChristmasSky mounted');
        this.startConfetti()
        // 手机端宽度数量减少到50
        // this.createSnowflakes(100, 0.025, 1.5);
    },
    methods: {
        startConfetti () {
            let confettiElement = document.getElementById("confetti-canvas");
            console.log('confettiElement', confettiElement);
            if (!confettiElement) {
                return;
            }
            const WIN_COLOR = {
                win: [
                    [255, 245, 255],
                    [207, 255, 207],
                    [186, 218, 255],
                    [255, 193, 59],
                    [255, 191, 202],
                    [255, 192, 3],
                ],
                nowin: [[255, 255, 255]],
            };
            let confettiSettings = {
                target: confettiElement,
                respawn: true,
                rotate: true,
                max: 100,
                clock: 12,
                start_from_edge: false,
                // 以下颜色需要转rgb

                colors: WIN_COLOR['nowin'],
                size: 0.6,
                props: [
                    "circle",
                    // "square",
                    // { "type": "svg", "src": "https://openmoji.org/data/color/svg/1F602.svg" }
                    // { "type": "svg", "src": svg }
                ],
            };
            this.confetti = new ConfettiGenerator(confettiSettings);
            this.confetti.render();
        },
        createSnowflakes (count, speed, size) {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            let width = canvas.clientWidth;
            let height = canvas.clientHeight;
            let i = 0;
            let active = false;

            function onResize () {
                width = canvas.clientWidth;
                height = canvas.clientHeight;
                canvas.width = width;
                canvas.height = height;
                ctx.fillStyle = '#FFF';

                const wasActive = active;
                active = width > 300;

                if (!wasActive && active)
                    requestAnimFrame(update);
            }

            const Snowflake = function () {
                this.x = 0;
                this.y = 0;
                this.vy = speed; // 减小雪花的下落速度
                this.vx = 0;
                this.r = size;

                this.reset();
            }

            Snowflake.prototype.reset = function () {
                this.x = Math.random() * width;
                this.y = Math.random() * -height;
                this.vy = speed + Math.random() * 3;
                this.vx = 0.5 - Math.random();
                this.r = 1 + Math.random() * 2;
                this.o = 0.5 + Math.random() * 0.5;
            }

            canvas.style.position = 'absolute';
            canvas.style.left = canvas.style.top = '0';

            const snowflakes = [];
            let snowflake;
            for (i = 0; i < count; i++) {
                snowflake = new Snowflake();
                snowflake.reset();
                snowflakes.push(snowflake);
            }

            function update () {

                ctx.clearRect(0, 0, width, height);

                if (!active)
                    return;

                for (i = 0; i < count; i++) {
                    snowflake = snowflakes[i];
                    snowflake.y += snowflake.vy;
                    snowflake.x += snowflake.vx;

                    ctx.globalAlpha = snowflake.o;
                    ctx.beginPath();
                    ctx.arc(snowflake.x, snowflake.y, snowflake.r, 0, Math.PI * 2, false);
                    ctx.closePath();
                    ctx.fill();

                    if (snowflake.y > height) {
                        snowflake.reset();
                    }
                }

                requestAnimFrame(update);
            }

            // shim layer with setTimeout fallback
            window.requestAnimFrame = (function () {
                return window.requestAnimationFrame ||
                    window.webkitRequestAnimationFrame ||
                    window.mozRequestAnimationFrame ||
                    function (callback) {
                        window.setTimeout(callback, 1000 / 60);
                    };
            })();

            onResize();
            window.addEventListener('resize', onResize, false);
        }
    }
}
</script>

<style scoped lang="scss">
.canvas-sky {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
}
#confetti-canvas {
    //height: calc(100vh - $header-menu-height - $header-menu-height);

}
.confetti-canvas-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 100vw;
    height: 100vh;
}
</style>

<template>
  <div class="nav-wrapper">
    <div class="px-2 model_title d-flex align-start justify-center">
      <v-btn x-small  color="white" text height="1.6rem" v-if="showBack" class="title_left pl-0" @click="back()">
        <v-icon left>
          {{ icons.mdiChevronLeft }}
        </v-icon>
        {{ backText != '' ? backText : '' }}
      </v-btn>
      <h1 class="title_model">{{ title }}</h1>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js';

export default {
  name: "user_modelHeader",
  props: {
    title: {
      type: String,
      default: "",
    },
    showBack: {
      type: Boolean,
      default: true
    },
    backText: {
      type: String,
      default: ''
    },
    backUrl: {
      type: String,
      default: ''
    },
  },

  data () {
    return {
      icons: { mdiChevronLeft }
    };
  },
  methods: {
    back () {
      if (this.backUrl != '') {
        console.log(this.backUrl)
        this.$router.push(this.backUrl)
      } else {
        this.$router.go(-1)
      }
    }
  },

  mounted () {
  },

};
</script>

<style lang="scss" scoped>
.nav-wrapper {
  z-index: 11;
  //padding-top: $header-menu-height;
  background-color: #000;
  color: #FFF;

}

.model_title {
  width: 100%;
  height: 2rem;
  box-sizing: border-box;
  position: relative;
  font-weight: bold;

  .title_left {
    position: absolute;
    left: 1rem;
  }
  .title_model {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) {
  .nav-wrapper {
    display: none;
  }
}
</style>

<template>
  <div class="variantsCard">
    <div class="variantsCard_content cflex">
      <div class="varcontent_title cflex">
        <span>Variants {{odds}}%</span>
        <div class="title_list varcontent_list">
          <div
            class="list_card cflex"
            v-for="(item, index) in variantsInfo"
            :key="index"
          >
            <template v-if="item.goodsProperty && item.goodsProperty.length>0">
              <span>{{ item.goodsProperty[0].propertyName }}</span>
              <span>{{ item.goodsProperty[0].value }}</span>
            </template>
          </div>
        </div>
      </div>
      <div class="varcontent_content">
        <div class="content_canbe">
          <span>Can be changed for</span>
          <div class="value">
            ${{ (price[0] / 100) | matterPriceDecimal }}-${{
              (price[1] / 100) | matterPriceDecimal
            }}
          </div>
        </div>
      </div>
      <div class="content_positionIcon" @click="$emit('close')">
        <v-img
          src="/assets/img/split/item-close.png"
          width="25"
          height="25"
          contain
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "variantsCard",
  props: {
    variantsInfo: {
      default: () => [],
    },
    odds: {
      default: 0.0,
    },
  },
  data() {
    return {};
  },

  mounted() {},
  computed: {
    price() {
      let priceData = [0,0];
      if(this.variantsInfo && this.variantsInfo.length>0){
        priceData = [this.variantsInfo[0].goodsPrice, 0];
        this.variantsInfo.forEach((item, index) => {
          if (item.goodsPrice < priceData[0]) priceData[0] = item.goodsPrice;
          if (item.goodsPrice > priceData[1]) priceData[1] = item.goodsPrice;
        });
      }
      return priceData;
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.variantsCard {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: var(--v-boxCardBg-base);
  padding: 16px;
  border-radius: 12px;
  font-size: 12px;
  box-sizing: border-box;
  position: relative;
  .variantsCard_content {
    width: 100%;
    height: 100%;
    .varcontent_title {
      font-size: 12px;
      color: #999;
      font-weight: 700;
      max-height: 60%;
      padding-bottom: 12px;
      border-bottom: 1px solid #ececec;
      .title_list {
        margin-top: 10px;
        width: 100%;
        .list_card {
          color: var(--v-black-base);
          background-color: var(--v-userLeftBg-base);
          width: 100%;
          height: 94px;
          padding: 14px 12px;
          box-sizing: border-box;
          border-radius: 4px;
          overflow: hidden;
          text-align: center;
        }
      }
      .varcontent_list {
        display: grid;
        grid-template-columns: repeat(auto-fill, 30%);
        grid-gap: 5px;
        @media (min-width: 100px) and (max-width: 600px) {
          grid-template-columns: repeat(auto-fill, 48%);
          grid-gap: 2%;
        }
        flex: 1;
        overflow-y: scroll;
        text-transform: uppercase;
      }
      .varcontent_list::-webkit-scrollbar {
        width: 5px;
        height: 10px;
        background-color: rgba(182, 182, 182, 0.374);
      }
    }

    .varcontent_content {
      font-weight: 700;
      text-align: left;
      & > div {
        margin-top: 12px;
        color: #999999;
        .value {
          color: var(--v-black-base);
        }
      }
    }
  }
  .content_positionIcon {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>

<template>
  <v-app>
    <HeaderCard v-if="routerState" :device="device" />

    <template v-if="$store.state.config.headerNoticeConfig.show">
      <HeaderNotice />
      <div class="notice_nav_placeholder"></div>
    </template>

    <v-main class="text-center">
      <!-- <loading /> -->
      <v-container fluid class="app-container">
        <template>
          <!-- 左侧路由页面 -->
          <div class="content_left lg:w-[92%]">
            <!-- 不需要缓存的视图组件 -->
            <router-view :device="device">
            </router-view>
          </div>

          <vue-lazy-component :timeout="600">
            <!-- 右侧固定滑块 -->
            <div class="content_right lg:w-[8%]" v-if="$breakpoint().ge('lg')">
              <div v-if="$store.state.config.headerNoticeConfig.show" class="notice_nav_placeholder"></div>
              <right-shop-box style="width: 100%"></right-shop-box>
            </div>
          </vue-lazy-component>
        </template>

        <!-- <div>
          <div class="body-content">
            <keep-alive include="unboxing">
              <router-view :device="device" />
            </keep-alive>
          </div>
        </div> -->
      </v-container>
    </v-main>

    <component :is="lazyComponents.login"></component>
    <component :is="lazyComponents.bottomNav" v-show="device !== 'PC'"></component>
    <vue-lazy-component :timeout="700">
      <component :is="lazyComponents.footer" v-show="showFooter" class="lg:w-[92%]" :device="device"></component>
      <component :is="lazyComponents.shepherdMixin" />
      <!-- 私聊聊天框  -->
      <component :is="lazyComponents.chatRoomModal" ref="chatRoomModalRef" />
      <component :is="lazyComponents.firstAccessModal" />
      <!--    充值-->
       <component :is="lazyComponents.recharge" v-if="lazyComponents.showRecharge" :device="device" />
      <!--    help-->
      <!--    <component :is="lazyComponents.helpBox" v-if="lazyComponents.showHelpBox"/>-->

    </vue-lazy-component>
    <ChristmasSky/>
  </v-app>
</template>

<script>

import { iosTouchScroll } from "@/utils/utils.js";
import ChristmasSky from "@/components/ChristmasSky.vue";
import HeaderCard from "@/components/headerCard.vue";
import HeaderNotice from "@/components/HeaderNotice.vue";
import rightShopBox from "@/components/rightShopBox.vue";
import { loginSuccessHandler,saveAnonymityHandler } from "@/views/sign/login.handler.js"
import { authLogin } from "@/api/user";
import { mapGetters } from "vuex";
import { toChat } from "@/api/chat";
// TODO 测试
// import {judgmentShepherdNewUser} from "@/plugins/shepherd";
import { BUS_NAME, CACHE_KEY } from "@/utils/constants";
import { checkAppUpdate } from "@/utils/appVersion"

export default {
  name: "App",
  // metaInfo() {
  //   return {
  //     title: this.appTitle,
  //     htmlAttrs: {
  //       lang: this.$i18n.locale
  //     }
  //   }
  // },
  provide () {
    return {
      reload: this.reload,
    };
  },
  data () {
    return {
      lazyComponents: {
        // 登录框
        login: null,
        loginParams: { modal: '' },
        // 新手引导
        shepherdMixin: null,
        chatRoomModal: null,
        firstAccessModal: null,
        bottomNav: null,
        footer: null,
        showFooter: false,
        recharge: null,
        // showRecharge: false,
        // helpBox: null,
        // showHelpBox: false,
      },
      lazyLoadDone: "",
      routerState: true,
      showMobileLiveDrops: false,
      timers: {
        getLiveDropsRankTime: null,
      },
    };
  },
  mounted () {

    // 去除 index.html 的懒加载 app-loading-container
    this.removeIndexHtmlLoading()
    this.handlerShowNotice()

    // 判断是否需要开启新用户引导 this.$bus.$emit(BUS_NAME.SHEPHERD_ACTIVE)

    // judgmentShepherdNewUser().then(done=>{
    //   if(!done){
    //     this.$bus.$emit(BUS_NAME.SHEPHERD_ACTIVE)
    //   }
    // })

  },
  created () {
    iosTouchScroll()
    this.getLiveDrops()
    this.getLiveDropsRank()
    this.checkApp()
    //缓存入vuex
    this.$store.dispatch("user/setDeviceType", this.device);
    // ?code=enDb5kkM8pK6E5q1LLndNcO8n6Viz1&state=77132e2ca93b7dd7dec8e7c81be65e57#/
    // localStorage.setItem("loginType",60)
    // if (!parseInt(localStorage.getItem("loginType"))) return;
    this.authLoginEvent()
    // 如果没有 referralCode 获取
    setTimeout(() => {
      if (this.$store.state.user.isLogin) {
        if (!this.$store.state.user?.commissionInfo?.referralCode) {
          this.$store.dispatch("user/getCommissionAccountInfo");
        }
      }
    }, 1200)
    try {
      let token = this.$store.state.user?.authorization?.accessToken
      if (this.$store.state.user.userId && token) {
        this.$root.initSocket();
      }
    } catch (err) {

    }

    //获取web配置数据
    this.$store.dispatch("config/getConfigList");
    let oldPath = localStorage.getItem("rechargePath");
    if (oldPath) {
      this.$router.push(oldPath);
      localStorage.removeItem("rechargePath");
    }

    if (this.device !== 'PC') {
      this.initBottomNav()
    }
    // setTimeout(()=>{
    //   this.initFirstAccessModal()
    // },2000)

    setTimeout(() => {
      this.initFooter()
    }, 1000)

    setTimeout(() => {
      if (this.$store.state.user.isLogin) {
        saveAnonymityHandler(this)
      }
    }, 2000)

    setTimeout(() => {
      if (this.$store.state.user.isLogin) {
        saveAnonymityHandler(this)
      }
    }, 2000)

    this.initBus()
    // 内测版不显示客服
    setTimeout(() => {
      this.initCrisp()
    }, import.meta.env.DEV ? 100 : 5000)

  },
  watch: {
    $route: {
      handler (to, from) {
        let showLiveDrops = to.meta?.showLiveDrops ?? true

        this.showFooter = to.meta?.showFooter ?? true

        if (showLiveDrops) {
          this.showMobileLiveDrops = true;
          // 单独关闭 某一端 H5
          if (this.device !== 'PC') {
            this.showMobileLiveDrops = to.meta?.showLiveDropsH5 ?? true
          } else {
            this.showMobileLiveDrops = to.meta?.showLiveDropsPC ?? true
          }
        } else {
          this.showMobileLiveDrops = false;
        }
      },
      deep: true, // 深度监听
      immediate: true,
    },
    device () {
      if (this.$route.meta) {
        if (this.device !== 'PC') {
          this.showMobileLiveDrops = this.$route.meta?.showLiveDropsH5 ?? true
        } else {
          this.showMobileLiveDrops = this.$route.meta?.showLiveDropsPC ?? true
        }
      }
      if (this.device !== 'PC') {
        this.initBottomNav()
      }
    },
    isLogin: {
      handler (val, oval) {
        if (val === true) {
          this.initChatRoomModal()
          setTimeout(() => {
            this.initWs()
          }, 1000)
        }
      },
      deep: true, // 深度监听
      immediate: true,
    },
    // fontInfo: {
    //   handler(newValue) {
    // this.setFont();
    // },
    // },
  },
  components: {
    ChristmasSky,
    HeaderCard,
    rightShopBox,
    HeaderNotice,
  },
  computed: {
    ...mapGetters({
      fontInfo: "config/fontInfo",
      isLogin: "user/isLogin",
      device: "user/device",
    }),
    // appTitle() {
    //   return this.$store.getters.appTitle
    // }
  },
  beforeDestroy () {
    for (let key in this.timers) {
      clearInterval(this.timers[key]);
    }
    for (let value in BUS_NAME) {
      this.$bus.$off(value)
      console.log(`this.$bus.$off(${value})`)
    }
  },
  methods: {
    handlerShowNotice () {
      // let flag = localStorage.getItem(this.$store.state.config.headerNoticeConfig.key)
      // let showNotice = flag != '1'
      let showNotice = true
      this.$store.state.config.headerNoticeConfig.show = showNotice
    },
    authLoginEvent () {
      let searchParams = new URLSearchParams(window.location.search);
      let code = searchParams.get("code");
      let state = searchParams.get("state");
      let type = parseInt(localStorage.getItem(CACHE_KEY.LOGIN_TYPE));
      let referralCode = localStorage.getItem(CACHE_KEY.REFERRAL_CODE)
      if (code && state) {
        authLogin({
          code,
          state,
          type,
          referralCode,
        }).then((res) => {
          this.$awn.success("Welcome back!");
          // regisrer: res.optional=1
          if (res?.data?.optional == 1) {
            // 注册
            loginSuccessHandler(this, res, 'register')
            this.$gtagEvent('ob_click', 'login', `register_google`)
          } else {
            loginSuccessHandler(this, res, 'login')
            this.$gtagEvent('ob_click', 'login', `login_google`)
          }
          // 去除地址栏参数
          this.$router.push({ query: {} });
        });
      }
    },
    initWs () {
      // 注册ws
      this.$ws0.initWs()
    },
    getLiveDropsRank () {
      this.$store.dispatch("appData/getLiveDropsRank")
      this.timers.getLiveDropsRankTime = setInterval(() => {
        this.$store.dispatch("appData/getLiveDropsRank")
      }, 1000 * 20)
    },
    getLiveDrops (start = true) {
      // 开启live drops
      this.$store.dispatch("appData/getLiveDrops", start)

      setTimeout(() => this.getLiveDrops(false), 5000)
    },
    removeIndexHtmlLoading () {
      // app-loading-container
      // 拿到待删除节点:
      let self = document.getElementById('app-loading-container');
      if (self) {
        // 拿到父节点:
        let parent = self.parentElement;
        // 删除:
        let removed = parent.removeChild(self);
      }
    },
    initCrisp () {
      import(`./plugins/crisp.js`)
    },
    initBus () {
      this.initShowLoginBus()
      this.initToChatBus()
      // this.initShowRecharge()
      this.initShepherdActiveBus()
    },
    initShowLoginBus () {

      this.$bus.$on(BUS_NAME.SHOW_LOGIN, (data) => {
        console.log(this.loginParams?.modal)
        console.log(this.loginParams?.modal)
        let { type = 'login', open = true, params } = data
        if (type == 'loginProModal') {
          if (params?.modal) {
            type = params?.modal
          } else if (this.loginParams?.modal) {
            type = this.loginParams?.modal
            this.loginParams = { modal: '' }
          }
        }
        this.loginParams = params || { modal: '' }
        console.log(type)
        let _components = {
          // 'loginProModal':import(`./views/sign/login.vue`),
          'login': () => import(`./views/sign/loginProModal.vue`),
          'loginProModal': () => import(`./views/sign/login.vue`),
          // 'login':import(`./views/sign/login.vue`),
          'register': () => import(`./views/sign/register.vue`),
          'setPassword': () => import(`./views/sign/setPassword.vue`),
        }
        this.lazyComponents.login = open ? () => _components[type]() : null
      });
    },
    initShepherdActiveBus () {
      this.$bus.$on(BUS_NAME.SHEPHERD_ACTIVE, (data) => {
        // TODO 暂时只有开发环境开启
        if (import.meta.env.DEV) {
          // this.lazyComponents.shepherdMixin =() => import('./components/ShepherdMixin.vue');
        }
      });
    },
    initToChatBus () {
      this.$bus.$on(BUS_NAME.TO_CHAT, (userInfo) => {
        //调取接口
        const { userId, nick } = userInfo;
        toChat({
          toUserId: userId,
        }).then((res) => {
          if (res.data) {
            let chatRoomModalRef = this.$refs?.chatRoomModalRef
            if (chatRoomModalRef) {
              this.$refs.chatRoomModalRef.handleShow({ ...res.data });
            } else {
              this.initChatRoomModal()
              this.$nextTick(() => {
                this.$refs.chatRoomModalRef.handleShow({ ...res.data });
              })
            }
          }
        })
      });
    },
    initShowRecharge () {
      this.$bus.$on(BUS_NAME.SHOW_RECHARGE, (showRecharge = false) => {
        let routeData = this.$router.resolve({
          name: 'recharge',
        })
        window.open(routeData.href, '_blank')
      });
    },
    initChatRoomModal () {
      this.lazyComponents.chatRoomModal = () => import(`./components/chat/ChatRoomModal.vue`)
    },
    initFirstAccessModal () {
      this.lazyComponents.firstAccessModal = () => import(`./components/FirstAccessModal.vue`)
    },
    initBottomNav () {
      setTimeout(() => {
        this.lazyComponents.bottomNav = () => import(`./components/BottomNav.vue`)
      }, 1000)
    },
    initFooter () {
      setTimeout(() => {
        this.lazyComponents.footer = () => import(`./components/FootCard.vue`)
      }, 1000)
    },
    checkApp () {
      if (import.meta.env.DEV) {
        window.__APP_VERSION__ = 9999
      }
      checkAppUpdate().then(res => {
        if (res.update) {
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      })
    },
    //页面重加载
    reload () {
      this.routerState = false;
      this.$nextTick(() => {
        this.routerState = true;
      });
    },
  },
};
</script>
<style lang="scss">

</style>
<style scoped lang="scss">
/*#app {*/
/*  font-family: Poppins;*/
/*  font-weight: 700;*/
/*}*/
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  height: 100%;
} */
.notice_nav_placeholder {
  height: $header-notice-height;
}
.body-content {
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.text-center {
  width: 100%;
}

.text-center .content_left {
  //width: 92%;
  height: 100%;
}

.text-center .content_right {
  //width: 8%;
  //width: 110px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 14;
}

.app-container {
  padding: env(safe-area-inset-top, 0) 0 0 0 !important;
  height: 100%;
}
// v-menu 滚动条
::v-deep .v-menu__content {
  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    border-radius: 1em;
    background-color: var(--v-dialog-base);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-darken3);
    transition: all .2s;
    border-radius: 10px;

    &:hover {
      background-color: rgba(95,95,95, .7);
    }
  }

}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0.7;
}
::v-deep .v-input {
	font-size: 12px!important;
  .v-label {
    font-size: 12px!important;
  }
}
::v-deep .v-list-item__title {
font-size: 12px!important;
}
::v-deep .v-tabs-items{
  background-color: transparent!important;
}

</style>

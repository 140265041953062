import { getAccountInfo,getWealthAccountInfo } from '@/api/user'
import { getNextFreePayTime as getNextFreePayTimeApi} from "@/api/wealth.js";
import { getCommissionAccount } from "@/api/affiliates";

import {closeWs} from '@/plugins/ws/ws'
const state = {
  isLogin: false,
  // 新手引导名称，('')
  shepherdName: '',
  liveDrops: true,
  showPrivateChat: true,
  device: '',
  dark: 'day',
  userId: '',
  userInfo: '',
  commissionInfo: '',
  authorization: '',
  nextOnliBoxPayTime: 0,
  accountInfo: {
    balance: 0,
  },
};

const mutations = {
  SET_LOGIN_STATUS(state, status) {
    state.isLogin = status
  },
  SET_SHEPHERD_NAME(state, value) {
    state.shepherdName = value
  },
  SET_LIVE_DROPS(state, status) {
    state.liveDrops = status
  },
  SET_SHOW_PRIVATE_CHAT(state, status) {
    state.showPrivateChat = status
  },
  SET_DEVICE(state, type) {
    state.device = type
  },
  SET_DARK(state, value) {
    state.dark = value
  },
  SET_USER_INFO(state, value) {
    state.userInfo = value
    if(value && value!==''){
      if(state.isLogin === false){
        state.isLogin = true
      }

    }
  },
  SET_COMMISSION_INFO(state, value) {
    state.commissionInfo = value
  },
  SET_USER_ID(state, value) {
    state.userId = value
    if(value && value!==''){
      state.isLogin = true
    }
  },
  SET_AUTHORIZATION(state, value) {
    state.authorization = value
    if(value && value!==''){
      state.isLogin = true
      state.userId = value.userId
    }
  },
  SET_ACCOUNT_INFO(state, value) {
    state.accountInfo = value
  },
  SET_NEXT_ONLIBOX_PAY_TIME(state, value) {
    state.nextOnliBoxPayTime = value
  }
};

const actions = {
  changeLogin({ commit }, status) {
    commit('SET_LOGIN_STATUS', status)
  },
  changeShepherdName({ commit }, name) {
    commit('SET_SHEPHERD_NAME', name)
  },
  setDeviceType({ commit }, type) {
    commit('SET_DEVICE', type)
  },
  setDark({ commit }, value) {
    commit('SET_DARK', value)
  },
  setUserInfo({commit,},value){
    commit('SET_USER_INFO',value)
  },
  openLiveDrops({commit,},value){
    commit('SET_LIVE_DROPS',true)
  },
  closeLiveDrops({commit,},value){
    commit('SET_LIVE_DROPS',false)
  },
  showPrivateChat({commit,},value){
    commit('SET_SHOW_PRIVATE_CHAT',true)
  },
  closeShowPrivateChat({commit,},value){
    commit('SET_SHOW_PRIVATE_CHAT',false)
  },

  getNextOnliBoxPayTime({commit,},value){
    getNextFreePayTimeApi().then(res=>{
      commit('SET_NEXT_ONLIBOX_PAY_TIME',res.data)
    })
  },
  clearNextOnliBoxPayTime({commit,}){
    commit('SET_NEXT_ONLIBOX_PAY_TIME', 0)
  },
  getUserInfo({commit,state},value){
    if(!state.isLogin){
      return
    }
    getAccountInfo().then(res=>{
      commit('SET_USER_INFO',res.data)
    })
  },
  getCommissionAccountInfo({commit,state},value){
    if(!state.isLogin){
      return
    }
    getCommissionAccount().then(res=>{
      commit('SET_COMMISSION_INFO',res.data)
    })
  },
  setUserId({commit,},value){
    commit('SET_USER_ID',value)
  },
  setAuthorization({commit,},value){
    commit('SET_AUTHORIZATION',value)
  },
  getAuthorization({commit,},value){
    getWealthAccountInfo().then(res=>{
      commit('SET_ACCOUNT_INFO',res.data)
    })
    commit('SET_AUTHORIZATION',value)
  },

  setAccountInfo({commit,},value){
    commit('SET_ACCOUNT_INFO',value)
  },
  getAccountInfo({commit,state},value){
    if(!state.isLogin){
      return
    }
    getWealthAccountInfo().then(res=>{
      commit('SET_ACCOUNT_INFO',res.data)
    })
  },
  logout({ commit }) {
    closeWs()
    commit('SET_USER_INFO','')
    commit('SET_USER_ID','')
    commit('SET_COMMISSION_INFO','')
    commit('SET_AUTHORIZATION','')
    commit('SET_ACCOUNT_INFO','')
    commit('SET_LOGIN_STATUS', false)
  }
};

const getters = {
  isLogin:state=>state.isLogin,
  shepherdName:state=>state.shepherdName,
  userId:state=>state.userId,
  userInfo:state=>state.userInfo,
  commissionInfo:state=>state.commissionInfo,
  authorization:state=>state.authorization,
  account:state=>state.account,
  dark:state=>state.dark,
  device:state=>state.device,
  liveDrops:state=>state.liveDrops,
  showPrivateChat:state=>state.showPrivateChat,
  nextOnliBoxPayTime:state=>state.nextOnliBoxPayTime,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
